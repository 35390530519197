import {
  call,
  put,
  takeEvery,
  takeLatest,
  select,
  all,
} from "redux-saga/effects";
import { submitFormAnswer } from "../../network/forms";
import actions from "../../actions";
import store from "../../store";
import { uploadFile, createFile } from "../../network/file";
import * as selectors from "../selectors";
const { SAVE_ANSWERS_FORMS, saveAnswersFormsComplete, fileUploaded } = actions;

function* performSaveFormAnswers(action) {
  try {
    let data = action.data;

    let direction = yield select(selectors.direction);
    const idForFiles = [];

    yield all(
      data?.Files?.map((item) => call(processFiles, [item], idForFiles))
    );
    let SubjectItem = [
      "Climate & Health",
      "Food, Smart Agriculture, and Water Systems",
      "Energy Transition",
      "Nature, Land Use, and Oceans",
      "Sustainable Development",
      "Climate Finance",
      "Gender Equality",
      "Youth and Education",
      "Climate Politics and Governance",
      "Capacity Building, Loss and Damage",
    ];
    let Subject;
    if (
      data?.Subject?.length > 0 &&
      SubjectItem?.includes(data?.Subject[0]?.value)
    ) {
      Subject = data?.Subject[0]?.value;
    }
    let emailItem;
    if (data?.item?.find((child) => child?.name?.startsWith("email"))) {
      let emails = data?.item?.find((child) =>
        child?.name?.startsWith("email")
      );

      emailItem = emails?.value;
    }
    let files;
    if (idForFiles?.length > 0) {
      files = idForFiles;
    }
    const result = yield call(submitFormAnswer, {
      formId: data?.formId,

      answers: [
        {
          item: data?.item.map((item) => item),
          files: files,
          subject: Subject,
          email: emailItem,
        },
      ],
    });

    if (result?.data?.success) {
      yield put(saveAnswersFormsComplete({ data: { ...result.data } }));
    } else {
      yield put(saveAnswersFormsComplete({ data: { ...result.data } }));
    }
  } catch (e) {
    console.log({ error: e });
    return;
  }
}
function* processFiles(item, idForFiles) {
  if (item && item?.length > 0) {
    var formData = new FormData();

    formData.append("fileKey", `${item[0]?.fileName}`);
    yield all(
      item?.map((file) => call(submitFiles, file, idForFiles, formData))
    );
  }
}
function* submitFiles(file, idForFiles, formData) {
  if (file?.value?.length > 0) {
    {
      file?.value?.map((file) => {
        const newfile = new File([file?.file], file?.file?.name, {
          size: file?.file.size,
          type: file?.file.type,
          lastModifiedDate: file?.file.lastModifiedDate,
          webkitRelativePath: file?.file.webkitRelativePath,
          lastModified: file?.file.lastModified,
        });
        Object.defineProperty(newfile, "custom_name", {
          value: file?.file.custom_name,
        });

        formData.append("files", newfile);
      });
    }
    let responseData = yield call(uploadFile, formData);
    let payload = [];
    const escapeFileName = (fileName) => {
      // Step 1: Escape special characters
      fileName = encodeURIComponent(fileName);

      return fileName;
    };

    responseData.data.files.map((file, index) => {
      payload.push({
        uuid: file.filename || null,
        title: {
          ar: escapeFileName(file.originalname),
          en: escapeFileName(file.originalname),
        },
        extension: file.extension,
        custom_name: file.custom_name,
        encoding: file.encoding,
        filename: file.filename,
        mimetype: file.mimetype,
        size: file.size,
        fieldname: file.fieldname,
      });
    });
    const filePayload = yield call(createFile, payload);

    filePayload?.data?.files?.map((files) =>
      idForFiles?.push({
        uuid: files.uuid,
        id: files.id,
        extension: files.extension,
        title: files.title,
        size: files.size,
        mimetype: files.mimetype,
      })
    );
  }
}
export function* watchSaveFormAnswers() {
  yield takeLatest(SAVE_ANSWERS_FORMS, performSaveFormAnswers);
}
