import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  imgbackGround: {
    display: "flex",
    backgroundImage: "url(/assets/images/noData.png)",
    backgroundPosition: "center",
    width: "100%",
    marginTop: "20px",
    flexDirection: "column",
    alignItems: "center",
    backgroundSize: "contain",
    paddingTop: "3%",
    height: "400px",
    backgroundRepeat: "no-repeat",
  },
  cardText: {
    fontSize: `${theme.globals.fontSize.s}px!important`,
    [theme.breakpoints.down(600)]: {
      fontSize: `${theme.globals.fontSize.s - 2}px!important`,
    },
  },
  morePaddingTop: {
    paddingTop: "41px!important",
  },

  noDataText: {
    fontFamily: `${theme.globals.fontFamily.en}!important`,
    width: "100%",
    textAlign: "center",
    fontSize: theme.globals.fontSize.lg,
    color:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.white}!important`
        : `${theme.palette.primary.main}!important`,
    fontWeight: `${theme.globals.fontWeight.lg}!important`,
    paddingTop: 16,
    minHeight: 110,
    [theme.breakpoints.down(600)]: {
      fontSize:
        theme.direction === "rtl"
          ? theme.globals.fontSize.s
          : theme.globals.fontSize.s + 2,
    },
  },
}));

export default useStyles;
