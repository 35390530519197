import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  HeaderBox: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    height: 150,
    zIndex: "1200!important",
  },
  visibleBox: {
    display: "flex",
    height: "130px",
    position: "fixed",
    width: "100%",
    top: "0px",
    zIndex: "1200!important",

    backgroundColor:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.primary}!important`
        : `${theme.palette.primary.main}!important`,
    [theme.breakpoints.down(840)]: {
      height: "70px",
    },
  },
}));
export default useStyles;
