import { Box, Container } from "@mui/material";
import React, { useCallback, useState } from "react";
import useStyles from "../../../styles/components/header/navBar";
import Settings from "../../settings/Settings";
import NavBarItem from "./NavBarItem";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Link } from "react-router-dom";

const language = JSON.parse(localStorage.getItem("t28Lang"))
  ? JSON.parse(localStorage.getItem("t28Lang"))
  : "en";

function NavBar({ greenlogo, visible }) {
  const classes = useStyles();
  const {
    basicTheme,
    basicTheme: { isRTL, isDark },
  } = useSelector((state) => state.theme_reducer);

  const [openSettings, setOpenSettings] = useState(false);

  const toggleSettingModal = useCallback(() => {
    setOpenSettings(!openSettings);
  }, [openSettings]);

  return (
    <Container
      maxWidth="xl"
      className={
        visible
          ? clsx(classes.navBarContainer, classes.heightNavBar)
          : classes.navBarContainer
      }
    >
      <Box className={visible ? classes.headerlogoVisible : classes.headerlogo}>
        <Link to={language + "/home"}>
          <img
            src={
              greenlogo && !visible && !isDark
                ? "/assets/images/logo.png"
                : "/assets/images/darkLogo.png"
            }
            alt="logo"
          />
        </Link>
      </Box>

      <NavBarItem visible={visible} toggleSettingModal={toggleSettingModal} />
      <Settings open={openSettings} toggleSettingModal={toggleSettingModal} />
    </Container>
  );
}
export default NavBar;
