import { Box, Container, Grid, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import clsx from "clsx";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../styles/components/contactUsForm/contactUsForm";
import AutoSize from "../mui/autoSize/AutoSize";
import Button from "../button/Button";
import PhoneInput from "../phone-input/PhoneInput";
import RadioButton from "../radioGroup/RadioGroup";
import Select from "../mui/select/Select";
import ReCAPTCHA from "react-google-recaptcha";
import actions from "../../redux/actions";
import * as Yup from "yup";
import { Formik } from "formik";
import SharedDialog from "../shared/sharedDialog/SharedDialog";
import TextField from "../mui/contactTextField/TextField";

const { newContact, getAll, clear } = actions;
const ContactUsForm = () => {
  const classes = useStyles();
  const contactReducers = useSelector((state) => state);
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [captchakey, setCaptchaKey] = useState(
    "6Lf2oVUmAAAAAM4WQz8WZcY8L8prfryCpLPcFEAG"
  );
  let lang = JSON.parse(localStorage.getItem("t28Lang"))
    ? JSON.parse(localStorage.getItem("t28Lang"))
    : "ar";
  const { t } = useTranslation();
  const [dialog, setDialog] = useState({
    status: "",
    open: false,
    title: "",
    summary: "",
    description: "",
  });
  const subject = [
    t("CONTACT_US_PAGE.ENQUIRY"),
    t("CONTACT_US_PAGE.QUESTION"),
    t("CONTACT_US_PAGE.SUGGESTION"),
    t("CONTACT_US_PAGE.COMPLAINT"),
    t("CONTACT_US_PAGE.OTHER"),
  ];
  const subjectValues = [
    "Enquiry",
    "Question",
    "Suggestion",
    "Complaint",
    "Other",
  ];
  const initialValues = {
    name: "",
    email: "",
    subject: null,
    message: "",
    responseTypes: "email",
    serviceId: 1,
    phoneNumber: "",
    code: "",
    type: "contact",
  };
  const handleClose = useCallback(() => {
    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
  }, []);

  useEffect(() => {
    let sort = "configuration";
    dispatch(getAll({ sort }));
    dispatch(clear({ data: {} }));
  }, []);

  useEffect(() => {
    if (
      contactReducers?.crud?.allReturned?.configurations &&
      contactReducers.crud?.allReturned.configurations?.filter(
        (item) => item?.key === "CAPTCHA_KEY"
      )[0]?.value
    )
      setCaptchaKey(
        contactReducers.crud?.allReturned.configurations?.filter(
          (item) => item?.key === "CAPTCHA_KEY"
        )[0]?.value
      );
  }, [contactReducers]);

  useEffect(() => {
    if (contactReducers?.contactUs?.addedContact?.success === true) {
      setSuccess(true);
      setDialog({
        open: true,
        title: t("CONTACT_US_PAGE.THANKS"),
        summary: t("CONTACT_US_PAGE.SUCCESS"),
        description: t("CONTACT_US_PAGE.SUCCESSMESSAGE"),
      });

      return;
    } else if (contactReducers?.contactUs?.addedContact?.success === false) {
      setSuccess(false);
      setDialog({
        open: true,
        title: t("CONTACT_US_PAGE.SORRY"),
        summary: t("CONTACT_US_PAGE.WRONG"),
        description: t("CONTACT_US_PAGE.TRYAGAIN"),
      });
    }
  }, [contactReducers]);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("ERROR.REQUIRED")),
    phoneNumber: Yup.string().required(t("ERROR.REQUIRED")),
    email: Yup.string()
      .email(t("ERROR.EMAIL"))
      .max(255)
      .required(t("ERROR.REQUIRED")),
    subject: Yup.string().required(t("ERROR.REQUIRED")),
    message: Yup.string().required(t("ERROR.REQUIRED")),
    code: Yup.string().required(t("ERROR.REQUIRED")).nullable(),
  });

  const onSubmit = (values) => {
    delete values["code"];
    values["responseTypes"] = [values["responseTypes"]];
    let payload = values;
    dispatch(newContact({ sort: "service/request", payload }));
  };

  return (
    <>
      {" "}
      <Container className={classes.root}>
        <Box className={classes.titleBox}>
          <Typography className={classes.title}>
            {t("CONTACT_US_PAGE.CONTACT")}
          </Typography>

          <Typography className={classes.desc}>
            {t("CONTACT_US_PAGE.MARKED_1")} (
            <span className={classes.required}>*</span>){" "}
            {t("CONTACT_US_PAGE.MARKED_2")}
          </Typography>
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
          setFieldValue
          validateOnChange={true}
          validateOnBlur={true}
        >
          {function MyForm(formik) {
            useEffect(() => {
              if (contactReducers?.contactUs?.addedContact?.success === true) {
                formik?.resetForm({
                  values: initialValues,
                });
              }
            }, [contactReducers]);
            return (
              <Grid
                container
                maxWidth="xl"
                spacing={3}
                className={classes.gridContainer}
              >
                <Grid item sm={6} xs={12} className={classes.grid}>
                  <TextField
                    type="text"
                    id="name"
                    name="name"
                    variant="outlined"
                    placeHolder={t("CONTACT_US_PAGE.NAME")}
                    height="35px"
                    required={true}
                    inputProps={{
                      className: "required",
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.grid}>
                  <TextField
                    type="text"
                    required={true}
                    id="email"
                    name="email"
                    variant="outlined"
                    placeHolder={t("CONTACT_US_PAGE.EMAIL_PLACEHOLDER")}
                    height="35px"
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  className={clsx(classes.grid, classes.mb9)}
                >
                  <PhoneInput
                    placeholder={t("CONTACT_US_PAGE.PHONE_PLACEHOLDER")}
                    value={formik.values.phoneNumber}
                    required={true}
                    name="phoneNumber"
                    sx={{ padding: "1px!important" }}
                    international
                    onChange={(e) => {
                      formik?.setFieldValue("phoneNumber", e);
                    }}
                    className={classes.phoneInputStyle}
                  />

                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <Typography className={classes.errorSelect}>
                      {formik.errors.phoneNumber}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  className={clsx(classes.grid, classes.mb9)}
                >
                  <Select
                    name="subject"
                    variant="outlined"
                    id="subject"
                    placeholder={t("CONTACT_US_PAGE.SUBJECT")}
                    required={true}
                    className={classes.select}
                    rootClass={classes.selectRoot}
                    dropdownStyle={classes.dropdownStyle}
                    menuItems={subject}
                    value={formik.values?.subject}
                    selectingValues={subjectValues}
                    onChange={formik?.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel
                    htmlFor="title"
                    className={classes.label}
                    required
                  >
                    {t("CONTACT_US_PAGE.MESSAGE")}
                  </InputLabel>
                  <AutoSize
                    className={classes.autoSize}
                    name="message"
                    id="message"
                    value={formik?.values?.message}
                    onChange={(e) => {
                      formik?.setFieldValue("message", e?.target?.value);
                    }}
                  />

                  {formik.touched.message && formik.errors.message ? (
                    <Typography className={classes.errorSelect}>
                      {formik.errors.message}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <Box className={classes.captchaBox}>
                    <ReCAPTCHA
                      sitekey={captchakey}
                      onChange={(value) => formik?.setFieldValue(`code`, value)}
                      hl={lang}
                    />
                  </Box>
                  {formik.touched.code && formik.errors.code ? (
                    <Typography className={classes.errorSelect}>
                      {formik.errors.code}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ marginTop: "8px" }}
                  className={classes.buttonGrid}
                >
                  <Button
                    buttondiv={classes.buttondiv}
                    label={t("BUTTON.SUBMIT")}
                    onClick={(e) => formik?.submitForm(e)}
                    textStyle={classes.titleButton}
                    primary={true}
                  />
                </Grid>
              </Grid>
            );
          }}
        </Formik>
        <SharedDialog
          open={dialog.open}
          onClose={handleClose}
          title={dialog.title}
          message={dialog.summary}
          description={dialog.description}
          buttons={true}
          handleOkay={handleClose}
          okayBtn={true}
          success={success}
        />
      </Container>
    </>
  );
};
export default ContactUsForm;
