import { fork } from "redux-saga/effects";
import * as crudSagas from "./groups/crud";
import * as files from "./groups/file";
import * as filterPostsSagas from "./groups/filterPosts";
import * as formsSagas from "./groups/forms";
import * as contactUsSagas from "./groups/contactUs";
import * as surveySagas from "./groups/survey";
import * as searchSagas from "./groups/search";

const sagas = Object.values({
  ...crudSagas,
  ...files,
  ...filterPostsSagas,
  ...contactUsSagas,
  ...surveySagas,
  ...searchSagas,
  ...formsSagas
});

export default function* rootSaga(getState) {
  for (let index = 0; index < sagas.length; index++) {
    yield fork(sagas[index]);
  }
}
