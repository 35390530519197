import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  "@keyframes fadeInUp": {
    from: {
      transform: "translate3d(0,40px,0)",
    },

    "to ": {
      transform: "translate3d(0,0,0)",
      opacity: " 1",
    },
  },

  " @keyframes updown": {
    " 0%": {
      transform: " translateY(-100%)",
    },

    " 50%": {
      transform: "translateY(100%)",
    },

    "100%": {
      transform: "translateY(-100%)",
    },
  },
  container: {
    position: "relative",
  },
  BoxButton: {
    textAlign: " center",
    position: "absolute",
    overflow: " hidden",
    boxShadow: "none",
    borderRadius: "50%!important",
    zIndex: "60!important",
    cursor: " pointer!important",
    top: "-2px",
    width: "60px!important",
    height: "60px!important",
    minHeight: "60px!important",
    minWidth: "60px!important",
    border: "none!important",
    background: `${theme.globals.colors.lightGreen}!important`,
    color: `${theme.globals.colors.white}!important`,
    [theme.breakpoints.down(600)]: {
      width: "50px!important",
      height: "50px!important",
      minHeight: "50px!important",
      minWidth: "50px!important",
    },

    "& >svg": {
      width: "25px!important",
      height: "25px!important",
    },
    "&:hover": {
      "& >svg": {
        animationName: "$fadeInUp",
        animationDuration: "1s",

        animationFillMode: "both",

        animationIterationCount: "infinite",
      },
    },
  },

  "@keyframes movebtn": {
    " 0%": {
      transform: "translateY(0px)",
    },
    "25%": {
      transform: "translateY(20px)",
    },
    " 50%": {
      transform: "translateY(0px)",
    },
    "75%": {
      transform: "translateY(-20px)",
    },
    " 100%": {
      transform: "translateY(0px)",
    },
  },
}));
export default useStyles;
