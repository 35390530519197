export const staticPages = [
  {
    link: "/news",
    parentPage: "News & Media",
    parentPageLink: "/gallery",
    pageLink: "#",
    page: "News",
  },
  {
    link: "/photos",
    parentPage: "News & Media",
    parentPageLink: "/gallery",
    pageLink: "#",
    page: "Photos ",
  },
  {
    link: "/videos",
    parentPage: "News & Media",
    parentPageLink: "/gallery",
    pageLink: "#",
    page: "Videos",
  },

  {
    link: "/researches",
    parentPage: "Research",

    page: "Research",
  },
  {
    parentPage: "Policy Briefs",
    page: "Policy Briefs",
    link: "/policy",
  },
  {
    parentPage: "Communique",
    link: "/Communique",
    page: "Communique",
  },
  {
    link: "/publications",
    pageLink: "#",
    page: "publications",
  },

  {
    link: "/task-forces",
    page: "Task Forces",
  },

  {
    link: "/search-results",
    parentPage: "Search Results",
  },
  {
    link: "/whatAboutT28",
    parentPage: "What is T28",
  },
];
