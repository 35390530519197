import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import crud from "./groups/crud";
import contactUs from "./groups/contactUs";
import files from "./groups/file";
import theme_reducer from "./theme";
import filterPostsReducers from "./groups/filterPosts";
import filterPosts from "./groups/filterPosts";
import surveys from "./groups/survey";
import homeLoading from "./groups/homeLoading";
import search from "./groups/search";
import forms from "./groups/forms";
const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    theme_reducer,
    crud,
    files,
    filterPostsReducers,
    contactUs,
    filterPosts,
    surveys,
    homeLoading,
    search,
    forms
  });

export default rootReducer;
