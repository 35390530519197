import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  menuBox: {
    backgroundColor:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.primary}!important`
        : `${theme.palette.primary.main}!important`,
    display: "flex!important",
    height: "45px",
    width: "100%",
    [theme.breakpoints.down(840)]: {
      height: "76px",
      paddingBlock: "16px",
    },
  },
  menuContainer: {
    display: "flex!important",

    "& *": {
      fontFamily:
        theme.direction === "rtl"
          ? `${theme.globals.fontFamily.ar}!important`
          : `${theme.globals.fontFamily.en}!important`,
    },
  },
  menuStyle: {
    display: "flex!important",
    listStyleType: "none",
    marginInlineEnd: "30px",
    width: "190px",
    maxWidth: "max-content",
    [theme.breakpoints.between(1310, 1460)]: {
      marginInlineEnd: "20px",
    },
    [theme.breakpoints.between(1220, 1310)]: {
      marginInlineEnd: "10px",
    },
    [theme.breakpoints.between(1200, 1220)]: {
      marginInlineEnd: "8px",
    },
    [theme.breakpoints.down(1130)]: {
      marginInlineEnd: "20px",
    },
    [theme.breakpoints.down(1070)]: {
      marginInlineEnd: "10px",
    },
    [theme.breakpoints.down(990)]: {
      marginInlineEnd: "6px",
    },
    [theme.breakpoints.down(970)]: {
      marginInlineEnd: "4px",
    },
    [theme.breakpoints.down(900)]: {
      marginInlineEnd: "2px",
    },
    [theme.breakpoints.down(880)]: {
      marginInlineEnd: "0px",
    },
    [theme.breakpoints.down(860)]: {
      marginInlineEnd: "-4px",
    },
    [theme.breakpoints.down(840)]: {
      display: "none!important",
    },
    position: "relative",
    "& > .subItem": {
      opacity: "0",
      display: "none",
      "& >li:nth-last-child(1)": {
        borderBottom: "none!important",
        marginBottom: "2px!important",
      },
      "& li": {
        marginBottom: "8px",
        borderBottom: `0.5px dashed ${theme.globals.colors.steel}`,
        marginInline: "2px",
        paddingBlockStart: "3px",
        "&:hover": {
          backgroundColor: `${theme.globals.colors.white}!important`,

          "& .subItemText": {
            color: `${theme.palette.primary.main}!important`,
          },
        },
      },
    },

    "& .MuiButton-root": {
      color: `${theme.globals.colors.white}!important`,
      whiteSpace: "nowrap",
      textTransform: "capitalize",
      fontSize: `${theme.globals.fontSize.sm}px!important`,
      height: "100%",
      "& >span": {
        color: `${theme.globals.colors.white}!important`,
      },
      [theme.breakpoints.down(1380)]: {
        fontSize: `${theme.globals.fontSize.sm - 1}px!important`,
      },
      [theme.breakpoints.down(950)]: {
        fontSize: `${theme.globals.fontSize.sm - 2}px!important`,
      },
      // [theme.breakpoints.down(840)]: {
      //   paddingInline: "4px!important",
      // },
    },

    "&:hover": {
      "& .MuiButton-root": {
        background: `${theme.globals.colors.white}!important`,
        height: "100%",
        borderRadius: "0px",
        "& >span": {
          color: `${theme.globals.colors.green}!important`,
          position: "relative",
        },
      },
      "& .subItem": {
        opacity: "1",
        display: "flex!important",
        flexDirection: "column",
        position: "absolute",
        filter: "drop-shadow(0 0 0.75rem  rgba(0, 0, 0, 0.1))",

        top: "44px!important",
        paddingBottom: "0px!important",
        left: "0px",
        maxWidth: "max-content!important",
        minWidth: "100%",
        backgroundColor:
          theme.palette.type === "dark"
            ? `${theme.globals.colors.card}!important`
            : `${theme.palette.primary.main}!important`,

        padding: "6px 0px",

        zIndex: "1202",
        "& .rectangle": {
          left: "0px",
          position: "absolute",
          top: "-7px",
          width: "22px",
          height: "8px",
          backgroundColor:
            theme.palette.type === "dark"
              ? `${theme.globals.colors.card}!important`
              : `${theme.palette.primary.main}!important`,

          zIndex: "3",

          clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
          pointerEvents: "none",
        },

        "& .subItemText": {
          color: `${theme.globals.colors.white}!important`,
          fontSize: `${theme.globals.fontSize.sm}px!important`,
          textTransform: "capitalize",
          fontWeight: `${theme.globals.fontWeight.m}!important`,
          display: "flex",
          whiteSpace: "nowrap",
          marginInlineStart: "12px",
          marginInlineEnd: "20px",
          fontFamily:
            theme.direction === "rtl"
              ? `${theme.globals.fontFamily.ar}!important`
              : `${theme.globals.fontFamily.en}!important`,
          animationName: "$animate-top",
          animationDuration: "0.5s",
          marginBlockEnd: "4px",
          animationFillMode: "forwards",
        },

        "& .MuiButton-root": {
          color: `${theme.globals.colors.white}!important`,
        },
      },
    },
  },
  sectionbox: {
    display: "none",
    [theme.breakpoints.down(840)]: {
      display: "flex!important",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  headerlogo: {
    width: "150px",
    height: "100%",
    [theme.breakpoints.down(460)]: {
      width: "145px",
    },
  },
  navBarBox: {},
  iconMenu: {
    color: `${theme.globals.colors.white}!important`,
    width: "40px",

    height: "40px",
  },
  boxMenu: {
    display: "flex",
    marginTop: "8px",
    // "& >div:first-child": {
    //   [theme.breakpoints.down(470)]: {
    //     display: "none!important",
    //   },
    // },
    // [theme.breakpoints.down(470)]: {
    //   marginTop: "0px",
    // },
  },
}));
export default useStyles;
