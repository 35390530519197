import { Box, Container, Grid } from "@mui/material";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import useStyles from "../../styles/components/footer/footerStyle";
import ButtonScroll from "../scrollButton/ScrollButton";
import { AboutSite } from "./aboutSite/AboutSite";
import FooterInfo from "./footerInfo/FooterInfo";
import { Tail } from "./tail/Tail";

function Footer({ menuItems, configurations }) {
  const classes = useStyles();

  let lang = JSON.parse(localStorage.getItem("t28Lang"))
    ? JSON.parse(localStorage.getItem("t28Lang"))
    : "en";
  return (
    <Container maxWidth="xl" className={classes.footerRoot}>
      <Box className={classes.buttonLineBox}>
        <Box className={classes.lineTop}></Box>

        <ButtonScroll />
      </Box>
      <Grid container className={classes.firstFooterSection}>
        <Grid item lg={3} md={12} xs={12} sm={12}>
          <FooterInfo />
        </Grid>
        <Grid
          item
          container
          className={classes.linkBox}
          lg={6}
          md={8}
          xs={12}
          sm={12}
        >
          {menuItems?.slice(0, 5).length > 0 ? (
            <Grid
              item
              sm={menuItems?.length <= 5 ? 12 : 6}
              xs={12}
              md={menuItems?.length <= 5 ? 12 : 6}
              lg={menuItems?.length <= 5 ? 12 : 6}
              className={classes.linkGrid}
            >
              <ul className={classes.links}>
                {menuItems.slice(0, 5)?.map((item) => (
                  <Link to={`/${lang + item?.link}`}>
                    <li>{item?.title}</li>
                  </Link>
                ))}
              </ul>
            </Grid>
          ) : null}
          {menuItems?.slice(5, 9).length > 0 ? (
            <Grid
              item
              sm={menuItems?.length <= 5 ? 0 : 6}
              xs={menuItems?.length <= 5 ? 0 : 12}
              md={menuItems?.length <= 5 ? 0 : 6}
              lg={menuItems?.length <= 5 ? 0 : 6}
              className={classes.linkGrid}
            >
              <ul className={classes.links}>
                {menuItems.slice(5, 9)?.map((item) => (
                  <Link to={`/${lang + item?.link}`}>
                    <li>{item?.title}</li>
                  </Link>
                ))}
              </ul>
            </Grid>
          ) : null}
        </Grid>
        <Grid item lg={3} md={4} xs={12} sm={12} className={classes.infoGrid}>
          <AboutSite configurations={configurations} />
        </Grid>
      </Grid>

      <Grid container className={classes.ThirdFooterSection}>
        <Tail configurations={configurations} />
      </Grid>
    </Container>
  );
}
export default memo(Footer);
