import MUIDataTable from "mui-datatables";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTranslation } from "react-i18next";

import * as moment from "moment";
import { useStyles } from "../../components/shared/table";
import { getFileUrl } from "../../components/shared/utils";

export default function FilesTable(props) {
  const { t } = useTranslation();
  let { files, setFiles } = props;
  const {
    basicTheme: { isRTL },
  } = useSelector((state) => state.theme_reducer);
  const { downloadCountCompleted } = useSelector((state) => state.files);
  const [tableFiles, setTableFiles] = useState([]);
  const dispatch = useDispatch();
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    if (!downloadCountCompleted?.success) return;
    const file = downloadCountCompleted.file;
    if (!file) return;
    let newFiles = tableFiles?.map((item) =>
      item?.id === file?.id
        ? { ...item, downloaded: file.downloaded }
        : { ...item, downloaded: item?.downloaded }
    );
    setTableFiles(newFiles);
  }, [downloadCountCompleted]);

  const getFiles = (files) => {
    const itemFiles = files?.filter((file) => {
      if (file) {
        const { publishMode, mimetype } = file;

        if (mimetype && mimetype.includes("image")) return false;
        return true;
      }

      return false;
    });
    return itemFiles;
  };

  const handleClick = (url, title) => {
    if (!url) return;

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", isRTL ? title?.ar : title?.en);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const columns = [
    t("FILES_TABLE.TITLE"),
    t("FILES_TABLE.TYPE"),
    t("FILES_TABLE.YAER"),

    t("FILES_TABLE.DOWNLOAD"),
  ];

  const createColumn = useCallback(
    (arr) => {
      if (arr) {
        let array = [];
        let obj = {};

        arr.map((item) => {
          obj["id"] = item?.id;
          obj[`${t("FILES_TABLE.TITLE")}`] =
            isRTL && item?.publishMode === 1
              ? item?.title?.ar || "لا يوجد عنوان"
              : item?.title?.en || "No Title";

          obj[`${t("FILES_TABLE.TYPE")}`] = item?.mimetype?.includes("pdf") ? (
            <img src="/assets/images/pdf.png" className="icon" alt="pdf icon" />
          ) : item?.mimetype?.includes("image") ? (
            <img src="/assets/images/img.png" className="icon" alt="img icon" />
          ) : (
            <img
              src="/assets/icons/xls.png"
              className="icon"
              alt="excel icon"
            />
          );

          obj[`${t("FILES_TABLE.YAER")}`] = moment(item?.createdAt)
            .format("YYYY")
            .replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));

          obj[`${t("FILES_TABLE.DOWNLOAD")}`] = (
            <span
              onClick={async () => {
                // dispatch(downloadCount({ id: item.id }));
                const url = await getFileUrl(item.uuid);
                handleClick(url, item.title);
              }}
            >
              <FileDownloadIcon />
            </span>
          );

          array.push(obj);
          obj = {};
          return null;
        });
        return array;
      }
    },
    [isRTL]
  );

  useEffect(() => {
    if (!files) return;
    if (!files?.length) return;
    setTableFiles(files);
  }, [files]);

  useEffect(() => {
    if (!tableFiles) return;
    if (!tableFiles?.length) return;
    const newFiles = getFiles(tableFiles);
    const data = createColumn(newFiles);
    setNewData(data);
  }, [tableFiles, createColumn]);

  const options = {
    filterType: "checkbox",
    selectToolbarPlacement: "none",
    // tableBodyHeight: "450px",
    // onRowClick: (rowData, rowMeta) =>
    //   store.dispatch(push(`/open-data/page/${data[rowMeta.rowIndex][7]}`)),
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    fixedHeader: false,
    textLabels: {
      filter: {
        title: t("FILES_TABLE.FILTERS"),
        reset: t("FILES_TABLE.RESET"),
      },
      pagination: {
        rowsPerPage: t("FILES_TABLE.PAGEROWS"),
        displayRows: t("FILES_TABLE.OF"),
      },
      selectedRows: {
        text: t("FILES_TABLE.SELECTED"),
      },
      body: {
        noMatch: t("FILES_TABLE.NOMATCH"),
      },
    },
  };

  const classes = useStyles();
  return (
    <Fragment>
      <MUIDataTable
        className={`${classes.root} ${classes.news}`}
        data={newData}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
}
