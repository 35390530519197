import { Button, Container, Box, IconButton } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import { uid } from "react-uid";
import useStyles from "../../../styles/components/header/menuBar";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderDialog from "../headerDialog/HeaderDialog";
import Settings from "../../settings/Settings";
import NavBarItem from "../navBar/NavBarItem";

const language = JSON.parse(localStorage.getItem("t28Lang"))
  ? JSON.parse(localStorage.getItem("t28Lang"))
  : "en";

const SubMenu = (childrens) => {
  return (
    <ul className="subItem">
      <Box className="rectangle"></Box>
      {childrens.map((item) => {
        return (
          <li key={uid(item)} style={{ listStyleType: "none" }}>
            <NavLink to={language + item?.link} className="subItemText">
              {item?.title}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

function MenuBar({ menuItems }) {
  const [size, setSize] = useState(10);
  useEffect(() => {
    if (window?.innerWidth < 1200) {
      setSize(8);
    }
  }, []);

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpenMenuBar = () => {
    setOpen(true);
  };
  const handleCloseMenuBar = () => {
    setOpen(false);
  };
  const [startSearch, setStartSearch] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [query, setQuery] = useState("");

  const toggleSettingModal = useCallback(() => {
    setOpenSettings(!openSettings);
  }, [openSettings]);

  const toggleSearchField = () => {
    setStartSearch(!startSearch);
  };

  return (
    <Box className={classes.menuBox}>
      <Container maxWidth="xl" className={classes.menuContainer}>
        {menuItems.slice(0, size).map((item) => {
          return (
            <li key={uid(item)} className={classes.menuStyle}>
              {item?.link ? (
                <Link to={`${language + item?.link}`}>
                  <Button key={uid(item)} className={classes.button}>
                    <span>{item?.title}</span>
                  </Button>
                </Link>
              ) : (
                <Button key={uid(item)} className={classes.button}>
                  <span>{item?.title}</span>
                </Button>
              )}

              {item?.childrens?.length > 0 && SubMenu(item?.childrens)}
            </li>
          );
        })}
        <Box className={classes.sectionbox}>
          <Box className={classes.headerlogo}>
            <Link to={`/${language}/home`}>
              <img src={"/assets/images/darkLogo.png"} alt="logo" />
            </Link>
          </Box>
          <Box className={classes.boxMenu}>
            <NavBarItem
              toggleSearchField={toggleSearchField}
              startSearch={startSearch}
              toggleSettingModal={toggleSettingModal}
            />

            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClickOpenMenuBar}
              color="inherit"
            >
              <MenuIcon className={classes.iconMenu} />
            </IconButton>
          </Box>
        </Box>
        <HeaderDialog
          open={open}
          handleClose={handleCloseMenuBar}
          menuItems={menuItems}
        />
        <Settings open={openSettings} toggleSettingModal={toggleSettingModal} />
      </Container>
    </Box>
  );
}
export default MenuBar;
