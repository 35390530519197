import { Box, Container, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import Banner from "../shared/banner/Banner";
import NoData from "../shared/noData/NoData";
import ReCAPTCHA from "react-google-recaptcha";
import { BsChatSquareQuote } from "react-icons/bs";
import { ReactFormGenerator, Registry } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import useStyles from "../../styles/components/formGenerator/formGenerator";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import SharedDialog from "../shared/sharedDialog/SharedDialog";
import actions from "../../redux/actions";
import { DropzoneArea } from "react-mui-dropzone";
import FilesTable from "./../../components/shared/FilesTable";
const language = JSON.parse(localStorage.getItem("t28Lang"))
  ? JSON.parse(localStorage.getItem("t28Lang"))
  : "en";
const { saveAnswersForms, getById, clear } = actions;

const MyDropZone = React.forwardRef((props, ref) => {
  const { Filename, onChange, data } = props;

  const [fileSettings, setFileSettings] = useState({});
  const reducers = useSelector((state) => state);
  const classes = useStyles();
  let page = "form";
  let name = data?.key_content ? data?.key_content.trim() : "Files";
  let number = data?.file_num ? data?.file_num : "3";
  const { t } = useTranslation();
  useEffect(() => {
    if (
      reducers.crud?.dataConfssReturned?.configurations &&
      reducers.crud?.dataConfssReturned?.configurations?.filter(
        (item) => item?.key === "FILE_CONFIG"
      )[0]?.value
    ) {
      setFileSettings(
        JSON.parse(
          reducers.crud?.dataConfssReturned?.configurations?.filter(
            (item) => item?.key === "FILE_CONFIG"
          )[0]?.value
        )
      );
    }
  }, [reducers]);

  return (
    <>
      <DropzoneArea
        ref={ref}
        showFileNames={true}
        maxFileSize={
          fileSettings && fileSettings[`${name}`]?.maxSize
            ? Number(fileSettings[`${name}`]?.maxSize)
            : props?.maxFileSize
            ? props?.maxFileSize
            : 25000000
        }
        alertSnackbarProps={{ autoHideDuration: 3000 }}
        filesLimit={parseInt(number)}
        acceptedFiles={
          fileSettings &&
          `${name}` in fileSettings &&
          fileSettings[`${name}`]["allowed-type"]
            ? fileSettings[`${name}`]["allowed-type"]
            : props.acceptedFiles
        }
      />
      <Box className={classes.flex}>
        <BsChatSquareQuote className={classes.HelperTextIcon} />
        <Box className={classes.helperText}>
          {t("HELPERTEXT.ALLOWEDEXTENSIONS") + ":  "}
          {`${name}` in fileSettings && fileSettings[`${name}`]["allowed-type"]
            ? "  " + fileSettings[`${name}`]["allowed-type"] + "  "
            : props.acceptedFiles
            ? props.acceptedFiles
            : " " + t("HELPERTEXT.ALLFILES") + "  "}
          {" &  " + t("HELPERTEXT.MAXFILESIZE")}{" "}
          {fileSettings && fileSettings[`${name}`]?.maxSize
            ? " " + fileSettings[`${name}`]?.maxSize / 1000000 + " "
            : props?.maxFileSize
            ? props?.maxFileSize
            : 25}
          {fileSettings && fileSettings[`${name}`]?.maxSize ? " MB" : " B"}
        </Box>
      </Box>
    </>
  );
});
Registry.register("MyDropZone", MyDropZone);

const ReactCaptacha = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled } = props;

  const reducers = useSelector((state) => state);

  const [captchakey, setCaptchaKey] = useState(
    "6Lf2oVUmAAAAAM4WQz8WZcY8L8prfryCpLPcFEAG"
  );

  useEffect(() => {
    if (
      reducers.crud?.dataConfssReturned?.configurations &&
      reducers.crud?.dataConfssReturned?.configurations?.find(
        (item) => item?.key === "CAPTCHA_KEY"
      ) &&
      reducers.crud?.dataConfssReturned?.configurations?.find(
        (item) => item?.key === "CAPTCHA_KEY"
      )?.value
    ) {
      setCaptchaKey(
        reducers.crud?.dataConfssReturned?.configurations?.find(
          (item) => item?.key === "CAPTCHA_KEY"
        )?.value
      );
    }
  }, [reducers.crud?.dataConfssReturned]);

  const [captchaValue, setCaptchaValue] = useState(defaultValue);
  return (
    <ReCAPTCHA
      ref={ref}
      sitekey={captchakey}
      key={captchakey}
      value={captchaValue}
      onChange={(value) => {
        setCaptchaValue(value);
      }}
      hl={"en"}
    />
  );
});

Registry.register("ReactCaptacha", ReactCaptacha);

const items = [
  {
    key: "Header",
  },
  {
    key: "Paragraph",
  },
  {
    key: "LineBreak",
  },
  {
    key: "TextInput",
  },
  {
    key: "FileUpload",
  },
  {
    key: "Dropdown",
  },
  {
    key: "Tags",
  },
  {
    key: "TextArea",
  },
  {
    key: "RadioButtons",
  },
  {
    key: "Checkboxes",
  },
  {
    key: "Image",
  },
  {
    key: "EmailInput",
  },
  {
    key: "NumberInput",
  },
  {
    key: "PhoneNumber",
  },
  {
    key: "Rating",
  },
  {
    key: "DatePicker",
  },
  {
    key: "HyperLink",
  },
  {
    key: "Range",
  },
  {
    key: "FieldSet",
  },
  {
    key: "Camera",
  },
  {
    key: "MyDropZone",
    element: "CustomElement",
    component: MyDropZone,
    type: "custom",
    field_name: "my_drop_zone",
    forwardRef: true,
    name: "Drop Zone",
    icon: "fa fa-cog",
    static: true,
    props: {
      test: "drop_zone",
    },

    label: "Forms",
  },

  {
    key: "ReactCaptacha",
    element: "CustomElement",
    component: ReactCaptacha,
    type: "custom",
    field_name: "my_captch",
    forwardRef: true,
    name: "Captcha",
    icon: "fa fa-cog",
    static: true,
    props: { test: "captch" },
    label: "Captcha",
  },
];

function FormGenerator() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [question, setQuestions] = useState([]);
  const classes = useStyles();
  const { id } = useParams();
  const [openThanksMessage, setOpenTanksMassege] = useState(false);
  const [result, setResult] = useState(false);
  const dispatch = useDispatch();
  const reducers = useSelector((state) => state);
  const [files, setFiles] = useState([]);
  const submitAnswer = (e) => {
    let Files = e?.filter(
      (x) =>
        x?.name?.startsWith("file_upload") ||
        x?.name?.startsWith("my_drop_zone")
    );
    let Subject = e?.filter((x) => x?.name?.startsWith("dropdown"))
      ? e?.filter((x) => x?.name?.startsWith("dropdown"))
      : null;
    let item = e?.filter(
      (x) =>
        !x?.name?.startsWith("file_upload") &&
        !x?.name?.startsWith("my_drop_zone")
    );
    let newItem = item
      .map((itemChild) => {
        if (itemChild.custom_name.startsWith("my_captch")) {
          return null;
        } else if (itemChild.custom_name.startsWith("email_input")) {
          if (
            question.filter((item) => item?.element === "EmailInput")[0]
              ?.sendEmailTo
          ) {
            let Email = question.filter(
              (item) => item?.element === "EmailInput"
            )[0]?.sendEmailTo;
            return {
              ...itemChild,
              sendEmailTo: Email,
            };
          } else {
            return itemChild;
          }
        } else {
          return itemChild;
        }
      })
      .filter(Boolean);

    const data = {
      Files,
      item: newItem,

      formId: reducers?.crud?.byIdReturned?.result?.id,
      Subject,
    };

    dispatch(saveAnswersForms({ data }));
  };

  useEffect(() => {
    dispatch(clear({ data: {} }));

    setOpenTanksMassege(false);
  }, []);

  useEffect(() => {
    dispatch(getById({ sort: "form", id }));
  }, [id]);

  useEffect(() => {
    if (reducers?.crud?.byIdReturned?.result) {
      setData(reducers?.crud?.byIdReturned?.result);

      if (
        reducers?.crud?.byIdReturned?.result?.questions &&
        reducers?.crud?.byIdReturned?.result?.questions?.englishForm
      ) {
        setQuestions(
          reducers?.crud?.byIdReturned?.result?.questions?.englishForm
        );
      }
    }
  }, [reducers?.crud?.byIdReturned?.result]);

  const handleOKay = (event) => {
    setOpenTanksMassege(false);
    window.location.reload();
  };

  useEffect(() => {
    if (data?.files?.length) setFiles(data?.files);
  }, [data]);

  useEffect(() => {
    if (reducers?.forms?.savingAnswersFormComplete?.success) {
      setResult({
        title: t("LABEL.THANKS"),
        description: t("ANSWER.FORMSANSWER"),
      });
      setOpenTanksMassege(true);
    }
    if (reducers?.forms?.savingAnswersFormComplete?.success === false) {
      setResult({
        title: t("LABEL.SORRY"),
        description: t("ANSWER.ANSWERERROR"),
      });
      setOpenTanksMassege(true);
    }
  }, [reducers?.forms?.savingAnswersFormComplete]);

  return (
    <>
      <Banner />
      <Container maxWidth="xl" className={classes.root}>
        {data?.body && (
          <Box className={classes.description}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: `${data?.body}`,
              }}
            />
          </Box>
        )}
        {data?.files?.length ? (
          <FilesTable files={data?.files} setFiles={setFiles} />
        ) : null}
        {question && question?.length > 0 ? (
          <ReactFormGenerator
            hide_actions={false}
            data={question}
            skip_validations={false}
            read_only={false}
            toolbarItems={items}
            action_name={t("BUTTON.SUBMIT")}
            form_action=""
            // back_name={t("LABEL.BACK")}
            onSubmit={(e) => submitAnswer(e)}
            // back_action="/dynamic-services"
          />
        ) : null}

        <SharedDialog
          open={openThanksMessage}
          title={result.title}
          handleOkay={(e) => handleOKay()}
          onClose={() => setOpenTanksMassege(false)}
          description={result.description}
          buttons={true}
          okayBtn={true}
          success={reducers?.forms?.savingAnswersFormComplete?.success}
        />
      </Container>
    </>
  );
}
export default FormGenerator;
