import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => {
  return {
    container: {},
    getInTouchBox: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      borderRadius: 4,
      border: "solid #E3E3E3 1px",
      backgroundImage: "url(/assets/images/getInTouch.png)",
      width: "100%",
      "& *": {
        fontFamily:
          theme.direction === "rtl"
            ? `${theme.globals.fontFamily.ar}!important`
            : `${theme.globals.fontFamily.en}!important`,
      },
      [theme.breakpoints.down(775)]: {
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    column: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    row: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "row",
    },
    getInTouchWord: {
      marginLeft: "30px !important",
      paddingLeft: 14,

      textTransform: "uppercase",

      color: `${theme.globals.colors.lightGreen}`,
      fontFamily:
        theme.direction === "rtl"
          ? `${theme.globals.fontFamily.ar}!important`
          : `${theme.globals.fontFamily.en}!important`,
      fontSize: `${theme.globals.fontSize.lg + 13}px!important`,
      fontWeight: `${theme.globals.fontWeight.lg}!important`,
      marginBottom: "30px!important",
      marginTop: "30px!important",
      [theme.breakpoints.down(775)]: {
        marginTop: "16px!important",
        marginBottom: "16px!important",
      },
      [theme.breakpoints.down(410)]: { marginLeft: "0px !important" },
      [theme.breakpoints.down(400)]: {
        fontSize: `22px !important`,
      },
    },
    svgICon: { fontSize: "35px !important" },
    icon: {
      marginLeft: 20,
      marginRight: 20,
      color: "#BFC2C8",
    },
    centerDots: {
      alignSelf: "center",
      color:
        theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : `${theme.palette.primary.main}!important`,
      display: "flex",
      justifyContent: "center",
    },
    leftLabel: {
      marginLeft: "8px !important",
      fontWeight: `${theme.globals.fontWeight.lg}!important`,
      color:
        theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : `${theme.palette.primary.main}!important`,
    },
    rightLabel: {
      color:
        theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : `${theme.palette.primary.main}!important`,
    },
    mapContainer: {
      width: "55%",

      position: "relative",
      direction: "ltr!important",
      marginTop: 25,
      marginRight: 15,
      marginBottom: 25,
      borderRadius: 122,
      height: "400px",
      [theme.breakpoints.down(775)]: {
        width: "100%",
      },
    },
    contactRow: {
      marginLeft: 15,
      marginBottom: "6px",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      "& p": {
        fontFamily:
          theme.direction === "rtl"
            ? `${theme.globals.fontFamily.ar}!important`
            : `${theme.globals.fontFamily.en}!important`,
        fontSize: `${theme.globals.fontSize.sm}px!important`,
      },
    },
    infoGrid: {
      marginLeft: 29,
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down(410)]: { marginLeft: 0 },
    },

    rightLabelBox: {
      width: "220px",
      [theme.breakpoints.down(355)]: { width: "150px" },
    },
    centerDotsBox: { width: "12px" },
    leftLabelBox: { width: "85px" },
    iconBox: {
      display: "flex",
      justifyContent: "center",
      width: "50px",
    },
    twitter: {
      color: "#55ACEE",

      display: "flex",
    },
    youtube: {
      color: "red",

      fontSize: 40,
      display: "flex",
    },
    facebook: {
      color: "blue",

      display: "flex",
      fontSize: 24,
    },

    leftBox: {
      // width: "47%",
      [theme.breakpoints.down(775)]: {
        width: "100%",
      },
    },
    descriptionLabel: {
      margin: "35px 45px!important",
      color:
        theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : `${theme.palette.primary.main}!important`,
      [theme.breakpoints.down(410)]: {
        margin: "25px 15px!important",
      },
    },
    gridBox: {
      marginLeft: "10px !important",
      paddingLeft: "32px",
    },
    socialMedia: {
      marginBottom: "25px !important ",
      marginTop: "25px !important ",
      marginLeft: "35px !important ",
      [theme.breakpoints.down(410)]: { marginLeft: "5px !important" },
    },
    socialGrid: {
      [theme.breakpoints.down(325)]: { justifyContent: "center" },
    },

    mediaBox: {
      marginLeft: "5px",
      marginRight: "5px",
      width: "65px",
      height: "55px",
      borderRadius: "5px",

      backgroundColor: "#ffffff",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    imagIcon: {
      width: "30px!important",
    },
  };
});
