import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useStyles from "../../../styles/components/footer/footerStyle";
import { MediaBox } from "../mediaBox/MediaBox";

export const Tail = ({ configurations }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    basicTheme: { isDark },
  } = useSelector((state) => state.theme_reducer);

  return (
    <>
      <Grid item xs={12} className={classes.linkRoot}>
        <Box className={classes.logoRoot}>
          <Box className={classes.line}></Box>
        </Box>
      </Grid>
      <Grid item container xs={12} className={classes.linkRootInfo}>
        <Grid item lg={6} md={6} sm={6} className={classes.titleInfoRoot}>
          <Typography className={classes.titleInfo}>
            {t("SITE.FOOTER")}
          </Typography>
        </Grid>
        <Grid lg={3} md={2} xs={0} sm={2}></Grid>
        {/* <Grid item lg={3} md={4} xs={12} sm={4} className={classes.mediaRoot}>
          <MediaBox configurations={configurations} />
        </Grid> */}
      </Grid>
    </>
  );
};
