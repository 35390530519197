import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles/components/header/header";
import NavBar from "./navBar/NavBar";
import MenuBar from "./menuBar/MenuBar";
function Header({ menuItems, greenlogo }) {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  let lang = JSON.parse(localStorage.getItem("t28Lang"))
    ? JSON.parse(localStorage.getItem("t28Lang"))
    : "en";

  useEffect(() => {
    let fromTop = document.documentElement.scrollTop;

    if (fromTop === 0) setVisible(false);
  }, [visible]);

  useEffect(() => {
    window.addEventListener(
      "scroll",
      function () {
        let fromTop = document.documentElement.scrollTop;
        if (fromTop === 0) setVisible(false);
        if (fromTop >= 40) setVisible(true);
      },
      false
    );
  }, []);
  return (
    <>
      <Box className={visible ? classes.visibleBox : classes.HeaderBox}>
        <MenuBar menuItems={menuItems} />
        <NavBar greenlogo={greenlogo} visible={visible} />
      </Box>
    </>
  );
}
export default Header;
