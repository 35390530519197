import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
export default function CustomPhoneInput(props) {
  return (
    <PhoneInput
      international
      {...props}
      placeholder={
        props.required ? props.placeholder + " *" : props.placeholder
      }
    />
  );
}
