import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  buttonCenter: {
    display: "flex",
    justifyContent: "center",
  },
  buttonBox: {
    "& .MuiButtonBase-root": {
      width: "120px",
      height: "45px",
    },
  },
  primary: {
    width: "150px",
    height: "70px",
    backgroundColor:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.card}!important`
        : `${theme.globals.colors.white}!important`,
    border:
      theme.palette.type !== "dark"
        ? `1px solid ${theme.globals.colors.platinum}!important`
        : `1px solid ${theme.globals.colors.field}!important`,
    color:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.field}!important`
        : `${theme.palette.primary.main}!important`,
    textTransform: "capitalize!important",
    "& >span": {
      color:
        theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : `${theme.palette.primary.main}!important`,
      fontSize: `${theme.globals.fontSize.sm}px`,
      textTransform: "capitalize!important",
    },
    "& >svg": {
      color:
        theme.palette.type === "dark"
          ? `${theme.globals.colors.field}!important`
          : `${theme.palette.primary.main}!important`,
    },

    "&:hover": {
      backgroundColor:
        theme.palette.type !== "dark" &&
        `${theme.globals.colors.lightGreen}!important`,
      color:
        theme.palette.type !== "dark" &&
        `${theme.globals.colors.white}!important`,
      "& >span": {
        color:
          theme.palette.type !== "dark" &&
          `${theme.globals.colors.white}!important`,
        "& >svg": {
          color:
            theme.palette.type !== "dark" &&
            `${theme.globals.colors.white}!important`,
        },
      },
    },

    [theme.breakpoints.down(880)]: {
      width: "120px",
      height: "60px",
    },
    [theme.breakpoints.down(500)]: {
      width: "110px",
      height: "50px",
    },
    [theme.breakpoints.down(380)]: {
      width: "90px",
      height: "45px",
    },
  },
  newsletter: {
    border: `2px solid ${theme.globals.colors.white}`,
    width: "100%",
    height: "45px",
    backgroundColor: `${theme.globals.colors.white}!important`,
    color: `${theme.globals.colors.green}!important`,
    textTransform: "capitalize!important",

    "& >span": {
      color: `${theme.globals.colors.green}!important`,
      fontSize: `${theme.globals.fontSize.sm}px`,
      textTransform: "capitalize!important",
    },
    borderRadius: "2px!important",
  },
  secondry: {
    border: `1px solid ${theme.globals.colors.platinum}!important`,
    color: ` ${theme.globals.colors.white}!important`,
    textTransform: "capitalize!important",
    width: "150px",
    height: "70px",
    background: "none!important",
    "& >span": {
      color: ` ${theme.globals.colors.white}!important`,
      fontSize: `${theme.globals.fontSize.s}px`,
      textTransform: "capitalize!important",
    },
    "& ::hover": {
      background: "none!important",
    },
  },
}));
export default useStyles;
