import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "../../../styles/components/shared/banner/bannerStyle";
import BreadCrumb from "../breadCrumb/BreadCrumb";
import { useParams } from "react-router-dom";
import actions from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
const { getData } = actions;
const Banner = (props) => {
  const [selectedPage, setSelectedPage] = useState({});
  const [pageTitle, setPageTitle] = useState(null);
  const [staticPageTitle, setStaticPageTitle] = useState("");
  let { title } = props;
  const classes = useStyles();
  const { alias } = useParams();
  const dispatch = useDispatch();
  const reducers = useSelector((state) => state);

  // useEffect(() => {
  //   const url = window.location.href;
  //   if (url) dispatch(getData({ sort: "rate/askfor", data: { url: url } }));
  // }, [window.location.href]);

  const {
    basicTheme,
    basicTheme: { isRTL, isDark },
  } = useSelector((state) => state.theme_reducer);

  useEffect(() => {
    if (title) setPageTitle(title);
    else if (selectedPage) {
      if (alias) {
        setPageTitle(selectedPage?.title);
        return;
      } else if (selectedPage?.page) {
        setPageTitle(selectedPage?.page);
        return;
      } else if (selectedPage?.category) {
        setPageTitle(selectedPage?.category);
        return;
      } else if (selectedPage?.parentPage) {
        setPageTitle(selectedPage?.parentPage);
      }
    }
  }, [title, selectedPage]);

  return (
    <>
      <Container
        className={classes.boxContainer}
        style={{
          backgroundImage: isDark
            ? "url(/assets/images/bannerDark.png)"
            : "url(/assets/images/banner.png)",

          zIndex: 9,
          maxWidth: "100%",
          backgroundColor: "#fff",
          backgroundPosition: "center",
        }}
      >
        <Container maxWidth="xl" className={classes.crumb}>
          <Box className={classes.crumbBox}>
            <Typography className={classes.pageTitle}>
              {pageTitle ? pageTitle : staticPageTitle}
            </Typography>
            <BreadCrumb
              aria-label="breadcrumb"
              className={classes.crumbDetail}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </Box>
        </Container>
      </Container>
    </>
  );
};
export default Banner;
