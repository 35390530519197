import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  footerRoot: {
    direction: `${theme?.direction}!important`,
    paddingTop: "5px",
    "& *": {
      fontFamily:
        theme.direction === "rtl"
          ? `${theme.globals.fontFamily.ar}!important`
          : `${theme.globals.fontFamily.en}!important`,
    },
  },
  firstFooterSection: {
    display: "flex!important",
    padding: "20px 0px 0px ",
    direction: `${theme.direction}!important`,
  },
  infoBox: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1200)]: {
      alignItems: "center",
      marginBottom: "25px",
      width: "100%",
    },
  },
  infoFooterLogo: {
    width: "90%",
    height: 85,
    "& img": {
      objectFit: "cover",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down(1250)]: {
      width: "75%",
    },
    [theme.breakpoints.down(1200)]: {
      width: "auto",
    },
  },
  infoDesc: {
    display: "flex",
    width: "90%",
    [theme.breakpoints.down(1200)]: {
      width: "auto",
    },
  },
  desc: {
    fontSize: `${theme.globals.fontSize.sm}px!important`,
    fontWeight: `${theme.globals.fontWeight.m}!important`,
    color: theme.globals.colors.gray,
    lineHeight: "23px",
  },
  linkBox: {
    display: "flex",
    paddingInlineEnd: "15px",
    width: "100%",
    [theme.breakpoints.between(600, 665)]: {
      maxWidth: "120%!important",
      width: "120%!important",
      minWidth: "120%!important",
    },
  },
  linksTail: {
    listStyle: "none",
    display: "flex!important",
    maxWidth: "100%",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
    "& li": {
      margin: "0px 10px",
      color: theme.globals.colors.gray,
      fontSize: `${theme.globals.fontSize.sm}px!important`,
      fontWeight: `${theme.globals.fontWeight.m}!important`,
      marginBottom: "8px",
      cursor: "pointer",
      lineHeight: "21px",
      transform: "scale(1)",
      transition: "transform 0.2s",

      "&:hover": {
        transform: "translateX(5px)",
      },
      [theme.breakpoints.down(1070)]: {
        fontSize: `${theme.globals.fontSize.sm - 1}px!important`,
      },
    },
  },
  links: {
    listStyle: "none",

    maxWidth: "100%",
    flexWrap: "wrap",
    [theme.breakpoints.between(600, 665)]: {},
    "& li": {
      margin: "0px 10px",
      color: theme.globals.colors.gray,
      fontSize: `${theme.globals.fontSize.sm}px!important`,
      fontWeight: `${theme.globals.fontWeight.lg}!important`,
      marginBottom: "8px",
      cursor: "pointer",
      lineHeight: "21px",
      transform: "scale(1)",
      transition: "transform 0.2s",

      "&:hover": {
        transform: "translateX(5px)",
      },
      [theme.breakpoints.down(600)]: {
        margin: "0px 0px",
        marginBottom: "8px",
      },
    },
  },
  rootInfo: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // justifyContent: "space-between",
  },
  aboutBox: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
  },
  iconInfo: {
    fontSize: `${theme.globals.fontSize.sm + 6}px!important`,
    color: theme.globals.colors.gray,
    marginInlineEnd: "10px!important",
  },
  iconInfoPosition: {
    fontSize: `${theme.globals.fontSize.sm + 6}px!important`,
    color: theme.globals.colors.gray,
    marginInlineEnd: "10px!important",
    minWidth: "20px!important",
    [theme.breakpoints.between(900, 1040)]: {
      fontSize: `${theme.globals.fontSize.sm + 13}px!important`,
    },
  },
  infoGrid: {
    [theme.breakpoints.between(600, 665)]: {
      maxWidth: "120%!important",
      width: "120%!important",
      minWidth: "120%!important",
      marginTop: "10px!important",
    },
  },
  linkGrid: {},
  titleInfo: {
    fontSize: `${theme.globals.fontSize.sm}px!important`,
    lineHeight: "21px",
    color: theme.globals.colors.gray,

    fontWeight: `${theme.globals.fontWeight.m}!important`,
    [theme.breakpoints.down(1465)]: {
      fontSize: `${theme.globals.fontSize.sm - 1}px!important`,
    },
    [theme.breakpoints.between(665, 780)]: {
      fontSize: `${theme.globals.fontSize.sm - 2}px!important`,
    },
  },
  titleInfoNum: {
    fontSize: `${theme.globals.fontSize.sm}px!important`,
    lineHeight: "21px",
    color:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.field}!important`
        : `${theme.globals.colors.steel}!important`,
    marginInlineStart: "8px!important",
    fontWeight: `${theme.globals.fontWeight.m}!important`,
    [theme.breakpoints.down(1465)]: {
      fontSize: `${theme.globals.fontSize.sm - 1}px!important`,
    },
    [theme.breakpoints.between(665, 780)]: {
      fontSize: `${theme.globals.fontSize.sm - 2}px!important`,
    },
  },
  ThirdFooterSection: {
    padding: "10px 0",
    display: "flex!important",
    [theme.breakpoints.down(1200)]: {
      padding: "0px",
    },
  },
  linkRootInfo: {
    paddingTop: "10px",
  },
  line: {
    display: "flex",
    height: "1px",
    backgroundColor: `${theme.globals.colors.slateGray}`,
    width: "100%",
  },
  lineTop: {
    display: "flex",
    height: "1px",
    backgroundColor: `${theme.globals.colors.slateGray}`,
    width: "93%",
  },
  buttonLineBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBlock: "6px",
    height: "0.8px",
  },
  titleInfoRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  logoBox: {
    marginInline: "20px",
  },
  menuTail: {
    display: "flex",
  },
  tailroot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  mediaRoot: {
    display: "flex!important",
    justifyContent: "flex-end!important",
  },
  mediaBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: "10px",
    },
  },
  icon: {
    fontSize: `${theme.globals.fontSize.lg + 2}px!important`,
    cursor: "pointer",
    color: `${theme.globals.colors.midGray}!important`,
  },
}));
export default useStyles;
