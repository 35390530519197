import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
    "& *": {
      fontFamily: `${theme.globals.fontFamily.en}!important`,
    },
    direction: theme?.direction === "rtl" ? "rtl!important" : "ltr!important",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        "& .MuiTypography-root": {
          fontFamily: `${theme.globals.fontFamily.en}!important`,
          direction:
            theme?.direction === "rtl" ? "rtl!important" : "ltr!important",
          textAlign: "start",

          "& .MuiDialogContent-root": {
            "& .MuiFormControl-root": {
              "& . MuiInputBase-root": {
                "& .MuiInputBase-input": {
                  fontFamily: `${theme.globals.fontFamily.en}!important`,
                  color: theme.globals.colors.gray,
                },
              },
              fontFamily: `${theme.globals.fontFamily.en}!important`,
              color: theme.globals.colors.gray,
            },
          },
        },
      },
    },
    "& div.MuiDialog-paper": {
      display: "none",
      [theme.breakpoints.down(600)]: {
        width: "90%",
        display: "flex",
        backgroundColor: `rgba(0, 0, 0, 0.8)!important`,
        height: "auto",
        maxWidth: "90%",
        minHeight: 130,
        position: "absolute",
        top: "5%",

        "& div.MuiDialogTitle-root": {
          fontFamily:
            theme.direction === "rtl"
              ? `${theme.globals.fontFamily.ar}!important`
              : `${theme.globals.fontFamily.en}!important`,
          color: theme.globals.colors.white,
          fontSize: theme.globals.fontSize.s,
          direction:
            theme.direction === "rtl" ? "rtl!important" : "ltr!important",
          textAlign: "center",
          paddingBottom: 0,
          height: 270,

          "& h2": {
            fontFamily:
              theme.direction === "rtl"
                ? `${theme.globals.fontFamily.ar}!important`
                : `${theme.globals.fontFamily.en}!important`,
            fontWeight: theme.globals.fontWeight.l,
            fontSize: theme.globals.fontSize.s,
            color: theme.palette.primary.main,
            "&:svg": {
              color: "blue",
            },
          },
        },

        "& div.MuiDialogContent-root": {
          display: "flex",
          flexDirection: "column",
          "& .MuiGrid-container": {
            marginTop: "0px!important",
            display: "flex",
            position: "relative",
            alignItems: "flex-end",
          },
          "& div.MuiDialogActions-root": {
            marginTop: "15px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            height: "100%",
            flex: "1 1 auto",
            padding: "0px!important",
            "& button": {
              width: 130,
              height: "40px",
              border: "1px solid",
              fontSize: theme.globals.fontSize.xxs,
              backgroundColor: `${theme.palette.primary.main}!important`,
              color: `${theme.palette.background.default}!important`,
              cursor: "pointer",
              borderRadius: 50,
              "&:hover": {
                backgroundColor: `${theme.palette.background.default}!important`,
                color: `${theme.palette.primary.main}!important`,
                border: "1px solid",
                borderColor: `${theme.palette.primary.main}!important`,
              },
            },

            "& span.MuiButton-label": {
              color: "blue",
              textTransform: "capitalize",
            },

            "& button:first-of-type": {
              fontFamily:
                theme.direction === "rtl"
                  ? `${theme.globals.fontFamily.ar}!important`
                  : `${theme.globals.fontFamily.en}!important`,
              fontSize: theme.globals.fontSize.sm - 1,
            },
            "& button:last-of-type": {
              fontFamily:
                theme.direction === "rtl"
                  ? `${theme.globals.fontFamily.ar}!important`
                  : `${theme.globals.fontFamily.en}!important`,
              fontSize: theme.globals.fontSize.sm - 1,
            },

            "& button.Mui-disabled": {
              opacity: "0.7",
            },
          },
        },
      },
    },
  },
  searchBox: {
    display: "flex",
    width: "300px",
    borderInlineEnd: `1px solid ${theme.globals.colors.card}`,
    [theme.breakpoints.down(710)]: {
      width: "245px",
    },
    [theme.breakpoints.down(655)]: {
      width: "220px",
    },
    [theme.breakpoints.down(625)]: {
      width: "195px",
    },
    [theme.breakpoints.down(600)]: {
      display: "none!important",
    },

    "& .MuiAutocomplete-root": {
      display: "flex",
      width: "100%",
      "& .MuiBox-root": {
        display: "flex",
        width: "100%",
        alignItems: "center",
        "& .MuiFormControl-root": {
          "& .MuiInputBase-root": {
            fontSize: `${theme.globals.fontSize.sm}px!important`,
            color: `${theme.globals.colors.white}!important`,
            fontWeight: `${theme.globals.fontWeight.s}!important`,
            fontFamily: `${theme.globals.fontFamily.en}!important`,
            [theme.breakpoints.down(625)]: {
              fontSize: `${theme.globals.fontSize.sm - 1}px!important`,
            },
          },
        },

        "& .MuiButtonBase-root": {
          color: `${theme.globals.colors.white}`,

          "& >svg": {
            [theme.breakpoints.down(550)]: {
              fontSize: `${theme.globals.fontSize.sm}px!important`,
            },
          },
        },
      },
    },
  },

  searchModal: {
    display: "none",
    [theme.breakpoints.down(600)]: {
      display: "flex",
    },
  },
  marginTop24: {
    "& *": {
      fontFamily:
        theme.direction === "rtl"
          ? `${theme.globals.fontFamily.ar}!important`
          : `${theme.globals.fontFamily.en}!important`,
    },
    marginTop: "0px",
    position: "relative",
    [theme.breakpoints.down(600)]: {
      marginTop: "24px",
    },
  },

  label: {
    color: `${theme.globals.colors.white}!important`,
    fontSize: `${theme.globals.fontSize.sm + 1}px!important`,
    fontWeight: `${theme.globals.fontWeight.lg}!important`,
  },

  searchIcon: {
    display: "none!important",
    borderRadius: "50%",

    backgroundColor:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.field}!important`
        : `${theme.palette.primary.main}!important`,
    alignItems: "center",
    justifyContent: "center",
    zIndex: "10",
    width: 35,
    height: 35,

    [theme.breakpoints.down(700)]: {
      display: "flex",
    },
    "&:hover": {
      backgroundColor: theme.globals.colors.white,
      border: "1px solid",
      borderColor: `${theme.palette.primary.main}`,
      "& svg": {
        color:
          theme.palette.type === "dark"
            ? `${theme.globals.colors.field}!important`
            : `${theme.palette.primary.main}!important`,
      },
    },
    "& svg": {
      fontSize: theme.globals.fontSize.m,
      color: theme.globals.colors.white,
    },
  },
  selectPaper: {
    direction: theme.direction,
    backgroundColor:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.card}!important`
        : `rgba(0, 0, 0, 0.5)!important`,
    color: "#fff!important",
    width: "115%",
    minWidth: "115%",
    left: "-5px",
    position: "relative",
    top: "5px",
    maxWidth: "115%",
    borderRadius: "0px!important",
    [theme.breakpoints.down(840)]: {
      top: "0px",
    },
    "& *": {
      fontFamily:
        theme.direction === "rtl"
          ? `${theme.globals.fontFamily.ar}!important`
          : `${theme.globals.fontFamily.en}!important`,
    },
    "& .MuiAutocomplete-listbox": {
      "& .MuiAutocomplete-option": {
        direction: `${theme.direction}!important`,
      },
    },
  },
  field: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none!important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none!important",
      position: "relative",
    },
    "& .MuiInputBase-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none!important",
      },
    },
    "& label+.MuiInputBase-root": {
      margin: "5px 0px!important",
    },
    "& input::placeholder": {
      color: theme.globals.colors.darkgray,
      fontSize: `${theme.globals.fontSize.m}px!important`,
    },
    "& .MuiAutocomplete-hasPopupIcon": {
      padding: "0px!important",
    },
    "& .MuiInputBase-root": {
      padding: "0px!important",
      color: `${theme.globals.colors.white}!important`,
      height: "35px!important",
      "&:before": {
        position: "absolute",
        content: '""',
        height: "1px",
        width: "100%",
        bottom: "0px",
        borderBottom: `1px solid ${theme.globals.colors.white}`,
      },
      "& input": {
        padding: "4px!important",
      },
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
      fontSize: theme.globals.fontSize.m,
    },
    "& .MuiInputLabel-shrink": {
      fontSize: `${theme.globals.fontSize.m}px!important`,
    },
  },
  formContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down(510)]: {
      flexDirection: "column",
    },
    "& .MuiGrid-root .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input":
      {
        fontFamily:
          theme.direction === "rtl"
            ? `${theme.globals.fontFamily.ar}!important`
            : `${theme.globals.fontFamily.en}!important`,
      },
  },
  buttonBoxCenter: {
    "& .MuiButtonBase-root": {
      width: "120px",
      height: "40px",
    },
  },
  iconButton: {
    width: "40px",
    height: "40px",
    paddingBottom: "0px!important",

    "& >svg": {
      color: `${theme.globals.colors.white}`,
      fontSize: `${theme.globals.fontSize.lg + 10}px!important`,
      position: "absolute",
      bottom: "-3px",
    },
  },
}));
export default useStyles;
