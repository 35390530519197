import actions from "../../actions";
import { combineReducers } from "redux";
const { SAVE_ANSWERS_FORMS, SAVE_ANSWERS_FORMS_COMPLETE, CLEAR } = actions;

export const savingFormAnswers = (state = false, action) => {
  switch (action.type) {
    case SAVE_ANSWERS_FORMS:
      return true;
    default:
      return state;
  }
};
export const savingAnswersFormComplete = (state = null, action) => {
  switch (action.type) {
    case SAVE_ANSWERS_FORMS_COMPLETE:
      return action.data;
    case CLEAR:
      return false;
    default:
      return state;
  }
};
export default combineReducers({
  savingFormAnswers,
  savingAnswersFormComplete,
});
