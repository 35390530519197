import { Field } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import useStyles from "../../../styles/components/contactTextField/textField";

function TextFieldComponent(props) {
  const classes = useStyles();
  const isRequired = !!props.required;

  return (
    <Field
      component={TextField}
      {...props}
      className={classes.textField}
      placeholder={`${props.placeHolder}${isRequired ? " *" : ""}`}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
      }}
      inputProps={{
        "aria-label": props.ariaLabel,
      }}
      sx={{ height: `${props.height}` }}
    />
  );
}

export default TextFieldComponent;
