export const themeList = [
  {
    id: 1,
    name: "default Theme",
    isActive: true,
    createdAt: "2023-03-10T13:41:49.211Z",
    updatedAt: "2023-03-10T13:41:49.211Z",
    elements: {
      type: "light",
      direction: "rtl",
      fontFamily: {
        ar: "Montserrat",
        en: "Poppins",
      },
      fontWeight: { s: 400, m: 500, lg: 600, xl: 700 },
      fontSizeG: [12, 60, 13, 30, 14, 16, 18, 45, 36, 14, 24, 28, 16],
      fontSize: {
        xl: 64,
        section: 48,
        title: 24,
        lg: 20,
        sm: 14,
      },
      colors: {
        primary: "#121212",
        black: "#121212",
        darkNavy: "#0F1121",
        gray: "#5A524C",
        charcoal: "#1C1C1C",
        white: "#fff",
        card: "#fff",
        yellow: "#f5de8a",
        lightBlack: "rgba(0, 0, 0, 0.5)",
        platinum: "#E3E3E3",
        silver: "#C4C4C4",
        steelGray: "#7D7D7D",
        lightgray: "#F2F2F2",
        lightGray: "#E8E8E8",
        darkGray: "#575757",
        steel: "#6F707A",
        green: "#00755C",
        slateGray: "#9E9E9E",
        lightGreen: "#00A984",
        darkBlue: "#007AA9",
        midGray: "#797979",
        red: "#E56C6C",
        GrayLight: "#9E9E9E",
        border: "#A7A7A7",
        textMed: "#4668c5",
      },
    },
  },
];
