import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    "& .Mui-error": {
      paddingLeft: "0px!important",
      marginLeft: "0px!important",
    },
    "& .MuiInputBase-root": {
      borderRadius: "0px",
      height: 45,
    },
    "& .MuiFormLabel-root": {
      "&.Mui-focused": {
        color: "#666",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "& .MuiInputBase-root.Mui-error": {
      "& .MuiInput-underline": {
        borderBottomColor: theme.palette.error.main,
      },
    },
  },
  inputRoot: {
    backgroundColor: "white",
    borderRadius: "0px",
  },
  input: {
    padding: "0px 12px",
  },
}));

export default useStyles;
