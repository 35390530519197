import { Box, IconButton, Paper, Slide, TextField, Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../redux/actions";
import useStyles from "../../styles/components/searchModal/searchModalStyles";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

let timeout;

const lang = JSON.parse(localStorage.getItem("t28Lang"))
  ? JSON.parse(localStorage.getItem("t28Lang"))
  : "en";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function SearchModal(props) {
  let { query, setQuery, openModal, closeModal, startSearch } = props;

  const { getSuggestions, getResults } = actions;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [searchStart, setSearchStart] = useState(false);
  const [searchWord, setSearchWord] = useState(null);
  const [searchScreen, setSearchScreen] = useState(false);

  useEffect(() => {
    if (window.location.pathname === `/${lang}/search-results`) {
      setSearchScreen(true);
    }
  }, []);
  const navigate = useNavigate();
  const reducers = useSelector((state) => state);

  const handleChange = async (e) => {
    setSearchWord(e?.target?.value);
    if (timeout) clearTimeout(timeout);
    if (e?.target?.value && e?.target?.value?.length > 2) {
      timeout = setTimeout(async () => {
        const data = {
          searchWord: e?.target?.value,
          limit: 3,
          offset: 0,
          spinner: "none",
        };
        setSearchStart(!searchStart);
        dispatch(getSuggestions({ sort: "search/elastic", data }));
      }, 1000);
    }
  };

  const renderOption = (props, option) => {
    if (option && option?.title) {
      if (option?.url) {
        return (
          <Link to={option?.url} {...props}>
            {option?.title[lang]}
          </Link>
        );
      } else {
        return <div {...props}> {option?.title[lang]}</div>;
      }
    }
  };

  const handleSearchSubmit = (event) => {
    let text = searchWord;
    setQuery("");
    const url = window.location.href?.split("/");
    let correctUrl = "/" + url?.filter((item, index) => index > 3)?.join("/");
    if (correctUrl === "/search-results") {
      const newEvent = new Event("searchPopUp");
      const data = {
        searchWord: text,
        sections: "all",
        limit: 3,
        offset: 0,
      };
      dispatch(getResults({ sort: "search/elastic", data }));
      window.dispatchEvent(newEvent);
    } else navigate(`${lang}/search-results`, { state: { query: text } });
  };

  useEffect(() => {
    if (!searchWord || searchWord?.length <= 2) {
      return setData([]);
    }
    if (
      reducers?.search?.getSuggestionsReturned?.searchSuggestions?.length > 0 &&
      searchWord?.length > 2
    ) {
      setData(reducers?.search?.getSuggestionsReturned?.searchSuggestions);
    } else if (
      reducers?.search?.getSuggestionsReturned?.searchSuggestions?.length === 0
    ) {
      setData([]);
    }
  }, [reducers?.search?.getSuggestionsReturned?.searchSuggestions, searchWord]);

  return (
    <>
      {/**Search TextField */}

      <Box
        className={classes.searchBox}
        style={{
          display: !searchScreen && startSearch ? "flex" : "none",
        }}
      >
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          classes={{ root: classes.autoComplete, popper: classes.popper }}
          options={data}
          value={query ? query : null}
          PaperComponent={({ children }) => (
            <Paper
              className={classes.selectPaper}
              style={{
                textTransform: "capitalize",
              }}
            >
              {children}
            </Paper>
          )}
          renderInput={(params) => (
            <Box
              onBlur={() => {
                props?.toggleSearchField();
              }}
            >
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  type: "search",
                }}
                variant="standard"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleSearchSubmit();
                    setSearchStart(!searchStart);
                  }
                }}
                onChange={handleChange}
              />

              <IconButton
                className={props?.iconClass}
                onClick={() => {
                  handleSearchSubmit();
                }}
              >
                <BiSearch />
              </IconButton>
            </Box>
          )}
          getOptionLabel={(option) => {
            if (option?.title) {
              return option?.title[lang];
            } else {
              return "";
            }
          }}
          filterOptions={(options) => {
            return options;
          }}
          renderOption={renderOption}
        />
      </Box>

      {/**Search Modal for small Screen */}
      <Box className={classes.searchModal}>
        <Dialog
          open={openModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModal}
          aria-describedby="alert-dialog-slide-description"
          className={classes.root}
        >
          <DialogContent>
            <Grid container spacing={2} className={classes.formContainer}>
              <Grid item xs={10} className={classes.marginTop24}>
                <Box className={classes.label}>{t("LABEL.TITLE")}</Box>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  classes={{ root: classes.autoComplete }}
                  options={data}
                  value={query ? query : null}
                  PaperComponent={({ children }) => (
                    <Paper
                      className={classes.selectPaper}
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          handleSearchSubmit();
                          setSearchStart(!searchStart);
                        }
                      }}
                      onChange={handleChange}
                      className={classes.field}
                    />
                  )}
                  getOptionLabel={(option) => {
                    if (option?.title) {
                      return option.title[lang];
                    } else {
                      return "";
                    }
                  }}
                  filterOptions={(options) => {
                    return options;
                  }}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  className={classes.iconButton}
                  onClick={(e) => {
                    handleSearchSubmit();
                    handleChange();
                    setSearchStart(!searchStart);
                  }}
                >
                  <BiSearch />
                </IconButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}

export default memo(SearchModal);
