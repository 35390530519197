import { Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDoubleRight } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { staticPages } from "../../../publicData";
import useStyles from "../../../styles/components/shared/banner/bannerStyle";
function BreadCrumb(props) {
  const reducers = useSelector((state) => state);
  const {
    basicTheme: { isRTL },
  } = useSelector((state) => state.theme_reducer);
  const { alias,id } = useParams();

  let { selectedPage, setSelectedPage } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const getLink = (link) => {
    let returnedLink = link;
    if (link === "/videos" || link === "/news" || link === "/photos")
      returnedLink = "/gallery";

    return returnedLink;
  };
  let lang = JSON.parse(localStorage.getItem("t28Lang"))
    ? JSON.parse(localStorage.getItem("t28Lang"))
    : "en";
  useEffect(() => {

    const url = window.location.href?.split("/");
    let correctUrl = "/" + url?.filter((item, index) => index > 3)?.join("/");

    let staticPage = staticPages?.find(
      (item) => item?.link === correctUrl || correctUrl?.includes(item?.link)
    );
    
    if(correctUrl?.includes("forms")&&id){
     
      setSelectedPage({
        ...selectedPage,
        parentPageLink: `/forms`,
        category:reducers?.crud?.byIdReturned?.result?.title,
        categoryLink: "#",
        parentPage: "Call for Proposals",
        title: reducers?.crud?.byIdReturned?.result?.title,
      });
    }
    if (correctUrl?.includes("taskForces")) {
      setSelectedPage({
        ...selectedPage,
        parentPageLink: `/task-forces/${reducers?.crud?.byAliasReturned?.category?.alias}`,
        parentPage:
          reducers?.crud?.byAliasReturned?.categoryTitle &&
          reducers?.crud?.byAliasReturned?.categoryTitle[lang],
        categoryLink: "#",
        category: reducers?.crud?.byAliasReturned?.title,
        title: reducers?.crud?.byAliasReturned?.title,
      });
    }t("LABEL.DETAILS")
    if (staticPage) {
  
      if (alias) {
        if (correctUrl?.includes("task-forces")) {
          const title = location?.state?.title;

          setSelectedPage({
            parentPageLink:
              staticPage?.parentPageLink && getLink(staticPage?.link),

            categoryLink: "#",
            category:
              reducers?.crud?.allReturned?.posts &&
              reducers?.crud?.allReturned?.posts[0]?.categoryTitle &&
              reducers?.crud?.allReturned?.posts[0]?.categoryTitle[lang]
                ? reducers?.crud?.allReturned?.posts[0]?.categoryTitle[lang]
                : title,
            title:
              reducers?.crud?.allReturned?.posts &&
              reducers?.crud?.allReturned?.posts[0]?.categoryTitle &&
              reducers?.crud?.allReturned?.posts[0]?.categoryTitle[lang]
                ? reducers?.crud?.allReturned?.posts[0]?.categoryTitle[lang]
                : title,
          });
        } else {
          setSelectedPage({
            ...selectedPage,
            parentPageLink:getLink(staticPage?.link),
            parentPage: staticPage?.parentPage,
            categoryLink: "#",
            category:staticPage?.page ? staticPage?.page + " "+t("LABEL.DETAILS") : staticPage?.parentPage + " "+t("LABEL.DETAILS"),
            title:
              reducers?.crud?.byAliasReturned?.title ||
              reducers?.surveys?.surveyByAliasComplete?.title,
          });
        }
      }
      else {
      
        setSelectedPage({
          ...selectedPage,
          parentPageLink: getLink(staticPage?.link),
          parentPage: staticPage?.title
            ? staticPage?.title
            : staticPage?.parentPage,
        });
      }
    }

    if (reducers?.crud?.dataMenusReturned?.menuItems?.length > 0) {
      let links = reducers?.crud?.dataMenusReturned?.menuItems?.filter(
        (item) => item?.id === 1 || item?.id === 2
      );
      let allLinks = [];
      let menuIems = [];
      links?.map((item) =>
        item?.childrens?.map((child) => menuIems?.push(child))
      );
      menuIems?.map((item) => {
        allLinks?.push({ ...item });
        item?.childrens?.map((child) =>
          allLinks?.push({
            ...child,
            parentLink: item?.link,
            parent_title: item?.title,
          })
        );
      });

      if (allLinks?.length > 0) {
        if (!alias) {
          let selectedPage = allLinks?.filter(
            (item) => item?.link === correctUrl
          )[0];
          if (selectedPage) {
            if (selectedPage?.parent_title)
              setSelectedPage({
                ...selectedPage,
                parentPageLink: selectedPage?.parentLink,
                parentPage: selectedPage?.parent_title,
                categoryLink: selectedPage?.link,
                category: selectedPage?.title,
              });
            else
              setSelectedPage({
                ...selectedPage,
                parentPageLink: selectedPage?.link,
                parentPage: selectedPage?.title,
              });
          }
        } else {
          let newUrl = correctUrl;

          let selectedPage = allLinks?.filter(
            (item) => item?.link === newUrl
          )[0];
          if (!selectedPage) {
         
            newUrl = correctUrl?.split("/");
            newUrl = "/" + newUrl?.filter((item, index) => index === 1);

            selectedPage = allLinks?.filter((item) => item?.link === newUrl)[0];
            if (
              !selectedPage &&
              !correctUrl?.includes("task-forces") &&
              !correctUrl?.includes("taskForces")&&!staticPage
            ) {
              newUrl = correctUrl?.split("/");
              newUrl =
                "/" +
                newUrl
                  ?.filter((item, index) => index > 0 && index < 3)
                  ?.join("/");
              selectedPage = allLinks?.filter(
                (item) => item?.link === newUrl
              )[0];
              setSelectedPage({
                ...selectedPage,
                category: t("LABEL.DETAILS"),
                title:
                  reducers?.crud?.byAliasReturned?.title ||
                  reducers?.surveys?.surveyByAliasComplete?.title,
              });
            }
          }
          if (!selectedPage && reducers?.crud?.dataReturned?.feedBack?.title)
            setSelectedPage({
              ...selectedPage,
              pageLink: "#",
              page: reducers?.crud?.dataReturned?.feedBack?.title,
            });
          if (newUrl && selectedPage) {
       
            if (selectedPage?.parent_title&&selectedPage?.parentLink)
              setSelectedPage({
                ...selectedPage,
                parentPageLink:  selectedPage?.parentLink,
                parentPage: selectedPage?.parent_title,
                categoryLink: selectedPage?.parentLink&&selectedPage?.parentLink ,
                category: selectedPage?.title,
              });
              else if(selectedPage?.parent_title&&!selectedPage?.parentLink){
            
                setSelectedPage({
                  ...selectedPage,
                  parentPage: selectedPage?.parent_title,
                  categoryLink: selectedPage?.parentLink&&selectedPage?.parentLink ,
                  category: selectedPage?.title,
                });
              }
            else {
              if (alias&& !correctUrl?.includes("page")) {
            
                setSelectedPage({
                  ...selectedPage,
                  parentPageLink: selectedPage?.link,
                  title:
                    reducers?.crud?.byAliasReturned?.title ||
                    reducers?.surveys?.surveyByAliasComplete?.title,
                  parentPage: selectedPage?.title,
                  category: selectedPage?.title + "  " + t("LABEL.DETAILS"),
                });
              }
             else if(alias && correctUrl?.includes("page")){
              setSelectedPage({
                ...selectedPage,
                parentPageLink: selectedPage?.link,
                title:
                  reducers?.crud?.byAliasReturned?.title ||
                  reducers?.surveys?.surveyByAliasComplete?.title,
                parentPage: selectedPage?.title,
          
              });
              } else {
               
                setSelectedPage({
                  ...selectedPage,
                  parentPageLink: selectedPage?.link,

                  parentPage: selectedPage?.title,
                  category: selectedPage?.title + "  " + t("LABEL.DETAILS"),
                });
              }
            }
          }
        }
      }
    }
  }, [reducers, window.location.href, alias, isRTL]);

  return (
    <>
      {selectedPage ? (
        <Breadcrumbs
          separator={<HiChevronDoubleRight />}
          aria-label="breadcrumb"
        >
          <Link to={`/${lang}/home`}>{isRTL ? "الرئيسية" : "home"}</Link>
          {selectedPage?.parentPage &&
          selectedPage?.parentPageLink !== undefined ? (
            <Link
              to={
                selectedPage?.parentPageLink
                  ? `/${lang + selectedPage?.parentPageLink}`
                  : "#"
              }
              className={classes.linkRoot}
            >
              {selectedPage?.parentPage}
            </Link>
          ) : selectedPage?.parentPage &&
            selectedPage?.parentPageLink === undefined ? (
            <Typography>{selectedPage?.parentPage}</Typography>
          ) : null}
          {selectedPage?.category ? (
            <Link
              to={
                selectedPage?.categoryLink
                  ? `/${lang + selectedPage?.categoryLink}`
                  : "#"
              }
            >
              {selectedPage?.category}
            </Link>
          ) : null}
          {selectedPage?.pageLink ? (
            <Link to={`/${lang + selectedPage?.pageLink}`}>
              {selectedPage?.page}
            </Link>
          ) : null}
        </Breadcrumbs>
      ) : null}
    </>
  );
}

export default memo(BreadCrumb);
