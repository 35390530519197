import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    objectFit: "cover",
    justifyContent: "center",
    display: "flex!important",
    overflow: "hidden",
    maxWidth: "100%",
    height: "auto!important",
    minHeight: "500px!important",
    flexDirection: "column",
    [theme.breakpoints.down(400)]: {
      justifyContent: "start",
      minHeight: "350px!important",
    },
    "& *": {
      fontFamily: `${theme.globals.fontFamily.ar}!important`,
    },
  },
  crumbBox: {
    backgroundImage: "url(/assets/images/whitecircle.png)",
    backgroundPosition: "center",
    height: 265,
    width: 700,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "& .MuiBreadcrumbs-root": {
      position: "absolute",
      bottom: 15,
    },
    [theme.breakpoints.down(750)]: {
      width: "90%",
    },
    [theme.breakpoints.down(450)]: {
      backgroundSize: "150px 150px",
      width: "100%",
    },
  },
  pageTitle: {
    fontWeight: `${theme.globals.fontWeight.lg}!important`,
    color: theme.globals.colors.white,
    fontSize: `${theme.globals.fontSize.title + 2}px!important`,
    display: "-webkit-inline-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "2",
    lineHeight: "49.28px",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 32,
    [theme.breakpoints.down(500)]: {
      fontSize: `${theme.globals.fontSize.lg + 2}px!important`,
      marginBottom: "0px!important",
    },
  },

  crumb: {
    display: "flex!important",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(400)]: {
      marginTop: "6%",
    },
    "& .MuiBreadcrumbs-ol ": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textTransform: "capitalize",
      justifyContent: "center",
      [theme.breakpoints.down(450)]: {
        marginBottom: "4%",
      },

      "& .MuiBreadcrumbs-li": {
        "& a": {
          fontFamily: `${theme.globals.fontFamily.ar}!important`,
          fontSize: `${theme.globals.fontSize.sm + 2}px!important`,
          color: `${theme.globals.colors.white}!important`,
          lineHeight: "136.9%",
          [theme.breakpoints.down(990)]: {
            fontSize: theme.globals.fontSize.sm - 1,
          },
          [theme.breakpoints.down(500)]: {
            fontSize: `${theme.globals.fontSize.sm}px!important`,
          },
          [theme.breakpoints.down(450)]: {
            fontSize: `${theme.globals.fontSize.sm - 2}px!important`,
          },
        },
        "& >p": {
          color: `${theme.globals.colors.yellow}!important`,
          pointerEvents: "none!important",
        },
      },
      "& .MuiBreadcrumbs-li:last-of-type": {
        "& a": {
          color: `${theme.globals.colors.yellow}!important`,
          pointerEvents: "none!important",
        },
      },
      "& .MuiBreadcrumbs-separator": {
        "& >svg": {
          color: `${theme.globals.colors.white}!important`,
          fontSize: `${theme.globals.fontSize.sm}px!important`,
        },
      },
    },
  },
  crumbDetail: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: 20,
    paddingLeft: 20,
    zIndex: 64,
    fontFamily: `${theme.globals.fontFamily.ar}!important`,
    fontSize: theme.globals.fontSize.l,
    color: theme.palette.primary.main,
    "& .MuiTypography-root >.MuiBreadcrumbs-ol > ": {
      fontFamily: `${theme.globals.fontFamily.ar}!important`,
      fontSize: theme.globals.fontSize.l,
      color: theme.palette.primary.main,
    },
  },
}));
export default useStyles;
