import React from "react";
import useStyles from "../../../styles/components/shared/noData/noData";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Typography, Box } from "@mui/material";
function NoData(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  let { card, morePaddingTop, survey, text, surveyNoQues } = props;
  return (
    <Box className={classes.imgbackGround}>
      <Typography
        className={
          morePaddingTop
            ? clsx(classes.noDataText, classes.morePaddingTop, classes.cardText)
            : card
            ? clsx(classes.noDataText, classes.cardText)
            : classes.noDataText
        }
      >
        {text
          ? text
          : survey
          ? t("LABEL.NOSURVEYQUES")
          : surveyNoQues
          ? t("LABEL.NOSURVEYITEM")
          : t("LABEL.NODATA")}
      </Typography>
    </Box>
  );
}

export default NoData;
