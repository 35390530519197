import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useStyles from "../../../styles/components/footer/footerStyle";
function FooterInfo() {
  const classes = useStyles();
  const {
    basicTheme,
    basicTheme: { isRTL, isDark },
  } = useSelector((state) => state.theme_reducer);
  const { t } = useTranslation();
  const language = JSON.parse(localStorage.getItem("t28Lang"))
    ? JSON.parse(localStorage.getItem("t28Lang"))
    : "en";
  return (
    <Box className={classes.infoBox}>
      <Box className={classes.infoFooterLogo}>
        <Link to={language + "/home"}>
          <img
            src={
              isDark ? "/assets/images/darkLogo.png" : "/assets/images/logo.png"
            }
            alt="logo"
          />
        </Link>
      </Box>
    </Box>
  );
}
export default FooterInfo;
