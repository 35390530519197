import { Box, Container, Grid, Typography } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import Banner from "../../components/shared/banner/Banner";
import { useStyles } from "../../styles/pages/contactus/contact-us";

import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaFacebookF } from "react-icons/fa";
import { useSelector } from "react-redux";
import ContactUsForm from "../../components/contactUsForm/ContactUsForm";
import Map from "../../components/map/Map";
export const ContactUs = ({ setLogo, setFollowColor }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const reducers = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [contactInfo, setContactInfo] = useState({
    toolFree: "",
    customerCare: "",
    website: "",
    facebook: "",
    email: "",
    twitter: "",
    phone: "",
    youtubeChannel: "",
    insta: "",
  });

  useEffect(() => {
    if (reducers?.crud?.byIdReturned) {
      setData(reducers?.crud?.byIdReturned?.result);
    }
  }, [reducers]);

  useEffect(() => {
    setLogo(false);
    setFollowColor(false);
  }, []);

  useEffect(() => {
    if (reducers.crud?.allReturned?.configurations) {
      const configurations = reducers.crud?.allReturned?.configurations || [];

      const keys = {
        TOOL_FREE: "toolFree", //done
        CUSTOMER_CARE: "customerCare", //done
        WEBSITE: "website", //done
        FACE_BOOK: "facebook", //done
        EMAIL: "email", //done
        TWITTER: "twitter", //done
        PHONE: "phone", //done
        YOU_TUBE: "youtubeChannel", //done
        INSTAGRAM: "insta", //working
      };
      const contactData = {};

      configurations.forEach((item) => {
        const key = keys[item?.key];
        if (key) contactData[key] = item?.value;
      });

      setContactInfo((prev) => ({ ...prev, ...contactData }));
    }
  }, [reducers.crud?.allReturned?.configurations]);

  return (
    <>
      <Banner title={"Contact-us"} />

      <Container className={classes.column}>
        <Box className={clsx(classes.getInTouchBox)}>
          <Box className={clsx(classes.leftBox)}>
            <Typography className={classes.getInTouchWord}>
              {t("CONTACT_US_PAGE.GET_IN_TOUCH")}
            </Typography>
            <Box className={classes.infoGrid}>
              <Box className={classes.iconBox}>
                <MailIcon className={classes.icon} />
              </Box>

              <Box className={classes.leftLabelBox}>
                <Typography className={classes.leftLabel}>
                  {" "}
                  {t("CONTACT_US_PAGE.EMAIL_LABEL")}{" "}
                </Typography>
              </Box>
              <Box className={classes.centerDotsBox}>
                <Typography className={classes.centerDots}>:</Typography>
              </Box>
              <Box className={classes.rightLabelBox}>
                <Typography className={classes.rightLabel}>
                  {t("CONTACT_US_PAGE.EMAIL_VALUE")}
                </Typography>
              </Box>
            </Box>
            <Typography className={classes.descriptionLabel}>
              {t("CONTACT_US_PAGE.DESCRIPTION")}
            </Typography>
          </Box>
        </Box>
        <ContactUsForm />
      </Container>
    </>
  );
};
