import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  navBarContainer: {
    display: "flex!important",
    justifyContent: "space-between",
    position: "fixed",
    height: 40,
    right: 0,
    left: 0,
    top: 80,
    "& *": {
      fontFamily:
        theme.direction === "rtl"
          ? `${theme.globals.fontFamily.ar}!important`
          : `${theme.globals.fontFamily.en}!important`,
    },
    [theme.breakpoints.down(840)]: {
      display: "none!important",
    },
    // [theme.breakpoints.down(470)]: {
    //   display: "flex!important",
    //   top: 60,
    // },
  },
  heightNavBar: {
    top: "60px!important",
  },
  searchTextInput: {
    width: "550px!important",
    color: `${theme.globals.colors.white}!important`,
    fontSize: `${theme.globals.fontSize.sm}px!important`,
    fontWeight: `${theme.globals.fontWeight.s}!important`,
    fontFamily: `${theme.globals.fontFamily.en}!important`,
  },
  headerlogo: {
    maxWidth: "260px",
    maxHeight: "95px",
    minWidth: "260px",
    minHeight: "95px",
    width: "260px",
    height: "95px",
    [theme.breakpoints.down(1380)]: {
      maxWidth: "240px",
      maxHeight: "88px",
      minWidth: "240px",
      minHeight: "88px",
      width: "240px",
      height: "88px",
    },
    // [theme.breakpoints.down(470)]: {
    //   maxWidth: "180px",
    //   maxHeight: "70px",
    //   minWidth: "180px",
    //   minHeight: "70px",
    //   width: "180px",
    //   height: "70px",
    // },
  },
  headerlogoVisible: {
    maxWidth: "198px",
    maxHeight: "80px",
    minWidth: "198px",
    minHeight: "80px",
    width: "198px",
    height: "80px",
    [theme.breakpoints.down(460)]: {
      maxWidth: "160px",
      maxHeight: "60px",
      minWidth: "160px",
      minHeight: "60px",
      width: "160px",
      height: "60px",
      position: "relative",
      top: "9px",
    },
  },
  navBarItem: {
    display: "flex",
    position: "relative",

    backgroundColor:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.primary}!important`
        : `${theme.globals.colors.lightBlack}!important`,
    padding: "6px!important",
    "& .MuiIconButton-root:hover": {
      background: "none!important",
    },
    "& >div:nth-last-child(1)": {
      borderInlineEnd: "none!important",
    },
    [theme.breakpoints.down(430)]: {
      paddingInline: "0px!important",
    },
    [theme.breakpoints.down(415)]: {
      background: "none!important",
    },
  },
  navItemBorder: {
    border: `1px solid ${theme.globals.colors.white}`,
    top: "10px",
  },
  containBoxSearch: {
    display: "flex",
    alignItems: "center!important",
    cursor: "pointer",
    paddingInlineEnd: "10px",
    borderInlineEnd: `1px solid ${theme.globals.colors.white}`,
    [theme.breakpoints.down(415)]: {
      borderInlineEnd: `none`,
    },
    "& >div >svg": {
      transform: "scale(1)",
      transition: "transform 0.3s",
    },
    "&:hover": {
      "& >div  >svg": {
        transform: "scale(1.2)",
      },
    },
  },
  icon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    padding: "10px",
    display: "grid!important",
    justifyContent: "center!important",
    alignItems: "center!important",
    [theme.breakpoints.down(530)]: {
      width: "35px",
      height: "35px",
    },
    [theme.breakpoints.down(415)]: {
      borderRadius: "50%",
      backgroundColor: `${theme.globals.colors.lightBlack}`,
    },
  },
  navBarIcon: {
    width: "100%!important",
    height: "100%!important",
    color: `${theme.globals.colors.white}!important`,
  },
  navBarTitle: {
    color: `${theme.globals.colors.white}!important`,
    fontSize: `${theme.globals.fontSize.sm}px!important`,
    fontWeight: `${theme.globals.fontWeight.s}!important`,
    [theme.breakpoints.down(1380)]: {
      fontSize: `${theme.globals.fontSize.sm - 1}px!important`,
    },
    [theme.breakpoints.down(530)]: {
      fontSize: `${theme.globals.fontSize.sm - 2}px!important`,
    },
    [theme.breakpoints.down(415)]: {
      display: "none!important",
    },
  },

  closeIcon: {
    height: "35px",
    width: "35px",
    color: "#fff",
  },

  searchIcon: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    width: "40px",
    color: "#fff",

    "& svg": {
      fontSize: "20px",
      color: theme.globals.colors.golden,
    },
  },

  inputStyle: {
    width: "100%",
    color: "#fff",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    "& input": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: theme.globals.fontSize.sm,

      paddingInlineStart: "10px!important",
      lineHeight: "16px",

      color: `${theme.globals.colors.white}`,
    },

    "& input::placeholder": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: theme.globals.fontSize.xs + 1,
      lineHeight: "16px",
      color: "#fff!important",
    },
  },
}));
export default useStyles;
