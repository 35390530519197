import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch, AiOutlineSetting } from "react-icons/ai";
import clsx from "clsx";
import useStyles from "../../../styles/components/header/navBar";
import SearchModal from "../../search/SearchModal";

const NavBarItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let { toggleSettingModal, visible } = props;
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [query, setQuery] = useState("");
  const [startSearch, setStartSearch] = useState(false);
  const [modal, setModal] = useState(false);
  const [searchScreen, setSearchScreen] = useState(false);

  let lang = JSON.parse(localStorage.getItem("t28Lang"))
    ? JSON.parse(localStorage.getItem("t28Lang"))
    : "en";

  const toggleSearchField = () => {
    setStartSearch(!startSearch);
    if (window?.innerWidth < 600) {
      setOpenModal(!openModal);
      setModal(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setQuery("");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setQuery("");
  };
  useEffect(() => {
    if (window.location.pathname === `/${lang}/search-results`) {
      setSearchScreen(true);
    } else {
      setSearchScreen(false);
    }
  }, [window.location.pathname]);
  return (
    <Box
      className={
        visible
          ? clsx(classes.navBarItem, classes.navItemBorder)
          : classes.navBarItem
      }
    >
      {!searchScreen ? (
        <SearchModal
          toggleSearchField={toggleSearchField}
          startSearch={startSearch}
          open={open}
          openModal={openModal}
          close={handleClose}
          closeModal={handleCloseModal}
          query={query}
          setQuery={setQuery}
          sx={{ zIndex: 100000 }}
          setModal={setModal}
        />
      ) : null}

      {/**Before Start Search*/}
      {!searchScreen && (
        <Box
          style={{
            display: startSearch && !modal ? "none" : "flex",
          }}
        >
          <Box className={classes.containBoxSearch} onClick={toggleSearchField}>
            <Box className={classes.icon} onClick={toggleSearchField}>
              <AiOutlineSearch className={classes.navBarIcon} />
            </Box>
            <Typography className={classes.navBarTitle}>
              {t("HEADER.SEARCH")}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        className={classes.containBoxSearch}
        onClick={(e) => {
          toggleSettingModal();
        }}
      >
        <Box className={classes.icon}>
          <AiOutlineSetting className={classes.navBarIcon} />
        </Box>
        <Typography className={classes.navBarTitle}>
          {t("HEADER.SETTINGS")}
        </Typography>
      </Box>
    </Box>
  );
};
export default NavBarItem;
