import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    display: "flex!important",
    flexDirection: "column",
    direction: `${theme?.direction}!important`,
    borderRadius: 4,
    border: "solid #E3E3E3 1px",
    paddingLeft: 24,
    paddingBottom: "24px!important",
    backgroundImage: "url(/assets/images/send-us.png)",
    "& *": {
      fontFamily:
        theme.direction === "rtl"
          ? `${theme.globals.fontFamily.ar}!important`
          : `${theme.globals.fontFamily.en}!important`,
    },
    [theme.breakpoints.down(1110)]: {
      height: "820px",
    },
    [theme.breakpoints.down(900)]: {
      height: "auto",
      paddingBottom: 16,
    },
  },
  mb9: {
    "& .PhoneInput": {
      marginBottom: 9,
    },
    "& .MuiInputBase-formControl": {
      marginBottom: 9,
    },
  },
  rootError: {
    marginTop: 20,
  },
  grid: {
    marginTop: "16px!important",
    "& .MuiTextField-root": {
      height: "auto!important",
      "& .MuiInputBase-root ": {
        height: "56px!important",
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.globals.colors.border}!important`,
        },
      },
    },
  },
  title: {
    textTransform: "uppercase",
    color: `${theme.globals.colors.lightGreen}`,
    fontFamily:
      theme.direction === "rtl"
        ? `${theme.globals.fontFamily.ar}!important`
        : `${theme.globals.fontFamily.en}!important`,
    fontSize: `${theme.globals.fontSize.lg + 13}px!important`,
    fontWeight: `${theme.globals.fontWeight.lg}!important`,
    marginBottom: "30px!important",
    marginTop: "30px!important",
    [theme.breakpoints.down(775)]: {
      marginTop: "16px!important",
      marginBottom: "16px!important",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: `22px !important`,
    },
  },
  desc: {
    fontFamily:
      theme.direction === "rtl"
        ? `${theme.globals.fontFamily.ar}!important`
        : `${theme.globals.fontFamily.en}!important`,
    fontSize: `${theme.globals.fontSize.sm + 2}px!important`,
    fontWeight: `${theme.globals.fontWeight.lg}!important`,
    color: `${theme.globals.colors.black}!important`,
    marginBottom: "8px!important",
    [theme.breakpoints.down(400)]: {
      fontSize: `${theme.globals.fontSize.s - 2}px!important`,
    },
  },
  titleBox: {
    paddingLeft: 25,
    [theme.breakpoints.down(400)]: {
      paddingLeft: "0 !important",
    },
  },
  checkBoxTitle: {
    marginTop: "4px!important",

    fontFamily:
      theme.direction === "rtl"
        ? `${theme.globals.fontFamily.ar}!important`
        : `${theme.globals.fontFamily.en}!important`,
    fontSize: `${theme.globals.fontSize.sm + 2}px!important`,
    fontWeight: `${theme.globals.fontWeight.lg}!important`,
    color: `${theme.globals.colors.black}!important`,

    marginBottom: "10px!important",

    [theme.breakpoints.down(1180)]: {
      fontSize: `${theme.globals.fontSize.xs}px!important`,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "14px !important",
    },
  },
  label: {
    fontSize: `${theme.globals.fontSize.s - 2}px!important`,
    color: `${theme.globals.colors.black}!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    marginBottom: "4px!important",

    "& span": {
      color: `${theme.globals.colors.black}!important`,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: `${theme.globals.fontSize.xs + 1}px!important`,
    },
  },

  phoneInputStyle: {
    border: "solid #A7A7A7 1px !important",
    direction: theme?.direction,
    "& .MuiInputBase-root .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
    "& .PhoneInputCountry": {
      background: theme.globals.colors.offWhite,
      // borderRadius: 5,
      border: "none!important",
      width: "45px",
      height: "56px",
      backgroundColor: "#FFF",
      padding: "4px",
      margin: "0",
    },
    "& .PhoneInputInput": {
      background: theme.globals.colors.offWhite,

      fontSize: theme.globals.fontSize.xs - 1,

      padding: "8px",

      height: 56,
      border: "none",
      // border: "solid #A7A7A7 1px !important",
      borderRadius: 0,

      "&:focus-visible": {
        // background: "none",
        border: "none!important",
        outline: "none",
      },

      "& .PhoneInputCountrySelect": {
        height: "17px",
        width: "30px",
        fontSize: theme.globals.fontSize.xs - 1,
        background: theme.globals.colors.offWhite,
        color: `${theme.globals.colors.black}!important`,
      },
    },
  },

  selectRoot: {
    display: "flex!important",
    position: "relative!important",
    border: "solid #A7A7A7 1px !important",
    backgroundColor: `${theme.globals.colors.white}!important`,
    borderRadius: 0,
    height: 56,
    "& div": {
      background: theme.globals.colors.offWhite,

      "& .MuiInputLabel-shrink": {
        display: "none",
      },
    },
    "& .Mui-error": {
      direction: `${theme?.direction === "rtl" ? "rtl" : "ltr"}!important`,
      alignSelf: "flex-start!important",
      margin: "0px!important",
      position: "relative",
      top: 25,
      fontSize: "0.75rem!important",
      [theme.breakpoints.down(400)]: {
        paddingLeft: "0 !important",
      },
    },
  },

  select: {
    background: theme.globals.colors.offWhite,
    direction: `${
      theme?.direction === "rtl" ? "rtl!important" : "ltr!important"
    }`,
    borderRadius: 5,
    position: "relative",
    marginTop: 10,

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none!important",
      padding: "0 10px!important",
      direction: `${
        theme?.direction === "rtl" ? "rtl!important" : "ltr!important"
      }`,
    },
    "& .MuiOutlinedInput-input ": {
      padding: "6px!important",
      direction: `${
        theme?.direction === "rtl" ? "rtl!important" : "ltr!important"
      }`,
      fontSize: `${theme.globals.fontSize.sm}px!important`,
      color: `${theme.palette.primary.main}!important`,
      marginRight: "4px!important",
    },

    width: "100%",
    display: "flex",
    outline: "none",

    color: `${theme.globals.colors.black}!important`,

    "& input::placeholder": {
      marginLeft: "4px!important",
    },
    "& .MuiSvgIcon-root": {
      right: "7px!important",
      left: "auto",
      // left: theme?.direction === "rtl" ? "auto" : "7px",
      // right: theme?.direction === "ltr" ? "auto" : "7px",
    },
  },

  dropdownStyle: {
    backgroundColor: "red!important",
    border: "solid #E3E3E3 1px",
    borderRadius: 0,
    // left: `${
    //   theme?.direction === "rtl" ? "185px!important" : "210px!important"
    // }`,
    // right: "auto!important",
    // direction: `${theme?.direction}!important`,
    // border: "0.1px solid none",

    // fontSize: `${theme.globals.fontSize.m}px!important`,

    "& .MuiMenuItem-root": {
      fontSize: `${theme.globals.fontSize.s}px!important`,
      backgroundColor: "black!important",
      fontWeight: "500!important",
      "& *": {
        fontFamily:
          theme.direction === "rtl"
            ? `${theme.globals.fontFamily.ar}!important`
            : `${theme.globals.fontFamily.en}!important`,
      },
    },
  },

  autoSize: {
    borderRadius: "5px!important",
    direction: `${theme?.direction}!important`,
    fontSize: theme.globals.fontSize.xs - 1,
    width: "100%",

    maxWidth: "100%",
    maxHeight: "150px!important",
    height: "150px  !important",
    minHeight: "60px",
    display: "flex",
    paddingTop: "7px!important",
    outline: "none",
    background: "#FFFFFF",
    paddingInline: "4px",
    border: "1px solid #BBB8B8",
    [theme.breakpoints.between(954, 900)]: {
      maxHeight: "50px!important",
      height: "50px",
      minHeight: "50px",
    },
    "& input::placeholder": {},
  },

  direction: {
    flexDirection: "column",
    display: "flex",
  },

  divRadio: {
    display: "flex!important",
    width: "100%!important",
    direction: `${theme?.direction}!important`,
    justifyContent: "flex-start!important",
    marginTop: "8px!important",
  },
  rowDirection: {
    display: "flex",
    flexDirection: "row!important",
  },
  errorSelect: {
    color: "#d32f2f",
    fontSize: "0.75rem!important",
    marginTop: "8px!important",
    direction: `${theme?.direction === "rtl" ? "rtl" : "ltr"}!important`,
    [theme.breakpoints.down(955)]: {
      fontSize: `${theme.globals.fontSize.xs - 3}px!important`,
    },
  },
  required: {
    color: `${theme.globals.colors.red}!important`,
  },
  radioBtn: {
    direction: `${theme?.direction}!important`,
    "& .MuiFormControlLabel-root": {
      marginLeft: `${
        theme?.direction === "rtl" ? "10px!important" : "0px!important"
      }`,
      marginRight: `${
        theme?.direction === "rtl" ? "0px!important" : "13px!important"
      }`,

      direction: `${theme?.direction}!important`,
      "& .MuiRadio-root": {
        color: theme.globals.colors.lightGreen,
        padding: "0px!important",
      },
    },

    "& .MuiTypography-body1": {
      marginLeft: "10px!important",
      fontStyle: "normal!important",
      fontWeight: "normal!important",
      fontSize:
        theme.direction === "rtl"
          ? `${theme.globals.fontSize.xs + 1}px!important`
          : `${theme.globals.fontSize.s}px!important`,
      lineHeight: "24px!important",
      color: `${theme.globals.colors.black}!important`,
      [theme.breakpoints.down(600)]: {
        fontSize:
          theme.direction === "rtl"
            ? `${theme.globals.fontSize.xs - 1}px!important`
            : `${theme.globals.fontSize.s - 2}px!important`,
      },
    },
  },

  errortext: {
    color: "#d32f2f!important",
  },
  captchaBox: {
    marginLeft: "-2px",
    marginBottom: "7px",
    marginTop: "-10px",
    "& > div > div > div > div > iframe": {
      position: "inherit",
      WebkitTransform: "scale(0.90) !important",
      WebkitTransformOrigin: "0 0 !important",
      right: theme.direction === "rtl" ? "29px" : "-1px",

      bottom: theme.direction === "rtl" ? "-17px" : "-17px",
      position: "relative",
      [theme.breakpoints.down(400)]: {
        WebkitTransform: "scale(0.75) !important",
        right: theme.direction === "rtl" ? "59px" : "-1px",
      },
    },
  },
  titleButton: {
    fontSize:
      theme.direction === "rtl"
        ? `${theme.globals.fontSize.xs + 1}px!important`
        : `${theme.globals.fontSize.s}px!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    textTransform: "capitalize",
  },

  gridContainer: {
    paddingLeft: 25,
    [theme.breakpoints.down(400)]: {
      paddingLeft: "0px !important",
    },
  },
  buttonGrid: {
    "& div ": {
      [theme.breakpoints.down(600)]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    [theme.breakpoints.down(400)]: {
      paddingTop: "0px !important",
    },
  },
}));
export default useStyles;
