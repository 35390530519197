import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    direction: theme?.direction === "rtl" ? "rtl!important" : "ltr!important",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        "& .MuiTypography-root": {
          fontFamily:
            theme.direction === "rtl"
              ? `${theme.globals.fontFamily.ar}!important`
              : `${theme.globals.fontFamily.en}!important`,
          direction:
            theme?.direction === "rtl" ? "rtl!important" : "ltr!important",
          textAlign: "start",
          width: "90%",
          "& .MuiDialogContent-root": {
            "& .MuiFormControl-root": {
              "& . MuiInputBase-root": {
                "& .MuiInputBase-input": {
                  fontFamily:
                    theme.direction === "rtl"
                      ? `${theme.globals.fontFamily.ar}!important`
                      : `${theme.globals.fontFamily.en}!important`,
                  color: theme.globals.colors.gray,
                },
              },
              fontFamily:
                theme.direction === "rtl"
                  ? `${theme.globals.fontFamily.ar}!important`
                  : `${theme.globals.fontFamily.en}!important`,
              color: theme.globals.colors.gray,
            },
          },
        },
      },
    },
    "& div.MuiDialog-paper": {
      width: "510px",
      height: "auto",
      minHeight: 220,
      backgroundColor: `${theme.globals.colors.card}!important`,
      "&::before": {
        position: "absolute",

        backgroundSize: "cover",
        width: "160px",
        height: "100%",
        left: "0px",
        top: "0px",
        content: '""',
        backgroundImage: "url(/assets/images/d.png)!important",
      },
      [theme.breakpoints.down(500)]: {
        width: "350",
      },

      "& div.MuiDialogTitle-root": {
        fontFamily:
          theme.direction === "rtl"
            ? `${theme.globals.fontFamily.ar}!important`
            : `${theme.globals.fontFamily.en}!important`,
        color: theme.globals.colors.white,
        fontSize: theme.globals.fontSize.s,
        direction:
          theme.direction === "rtl" ? "rtl!important" : "ltr!important",
        textAlign: "center",
        paddingBottom: 0,
        height: 270,

        "& h2": {
          fontFamily:
            theme.direction === "rtl"
              ? `${theme.globals.fontFamily.ar}!important`
              : `${theme.globals.fontFamily.en}!important`,
          fontWeight: theme.globals.fontWeight.l,
          fontSize: theme.globals.fontSize.s,
          color: theme.palette.primary.main,
          "&:svg": {
            color: "blue",
          },
        },
      },

      "& div.MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
        paddingTop: "0px!important",
        marginTop: "10px!important",

        "& div.MuiDialogActions-root": {
          marginTop: "15px",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          height: "100%",
          flex: "1 1 auto",
          padding: "0px!important",

          "& span.MuiButton-label": {
            color: "blue",
            textTransform: "capitalize",
          },

          "& div  ": {
            "& a": {
              "& button": {
                background: `${theme.globals.colors.green}!important`,
                "& span": {
                  color: `${theme.globals.colors.white}!important`,
                },
                "&:hover": {
                  background: `${theme.globals.colors.white}!important`,
                  color: `${theme.globals.colors.green}!important`,
                  "& span": {
                    color: `${theme.globals.colors.green}!important`,
                  },
                },
              },
            },
          },
          "& button:last-of-type": {
            fontFamily:
              theme.direction === "rtl"
                ? `${theme.globals.fontFamily.ar}!important`
                : `${theme.globals.fontFamily.en}!important`,
            fontSize: theme.globals.fontSize.xxs,
          },

          "& button.Mui-disabled": {
            opacity: "0.7",
          },
        },
      },
    },
  },
  icon: {
    fontSize: `${theme.globals.fontSize.title}px!important`,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  iconBox: {
    borderRadius: "50%",
    background: "#E5E5E5",
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titleBox: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    height: "75px",
    width: "100%",
    backgroundImage: "url(/assets/images/sectionBackground.png)!important",
    backgroundPositionY: 5,
    backgroundSize: "85px 70px",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    paddingRight: 10,
    "& i": {
      fontSize: `${theme.globals.fontSize.title}px!important`,
      color:
        theme.palette.type === "dark"
          ? `${theme.palette.primary.main}!important`
          : `${theme.globals.colors.field}!important`,
      border: "unset!important",
      margin: "20px 10px!important",
      cursor: "pointer",
      width: "10%",
    },
  },
  message: {
    fontSize: `${theme.globals.fontSize.s}px!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    color: `${theme.globals.colors.primary}!important`,
  },
  title: {
    color: theme.globals.colors.green,
    fontSize: `${theme.globals.fontSize.lg}px!important`,
    fontWeight: `${theme.globals.fontWeight.lg}!important`,
    width: "fit-content",
    textTransform: "capitalize",
    position: "relative",
    top: 5,
    left: 25,
  },
  titleFail: {
    color: theme.globals.colors.green,
    fontSize: `${theme.globals.fontSize.lg}px!important`,
    fontWeight: `${theme.globals.fontWeight.lg}!important`,
    width: "fit-content",
    textTransform: "capitalize",
    position: "relative",
    top: 5,
    left: 25,
  },
  description: {
    fontSize: `${theme.globals.fontSize.s}px!important`,
    textTransform: "capitalize",
    color: `${theme.globals.colors.gray}!important`,

    "& .one": {
      fontSize: "30px",
      opacity: 0,
      animationDelay: "0.0s",
      animation: "$dot 1.3s infinite",
      animationFillMode: "forwards",
    },

    "& .two": {
      fontSize: "30px",
      opacity: 0,
      animationDelay: "0.2s",
      animation: "$dot 1.3s infinite",
      animationFillMode: "forwards",
    },

    "& .three": {
      fontSize: "30px",
      opacity: 0,
      animationDelay: "0.3s",
      animation: "$dot 1.3s infinite",
      animationFillMode: "forwards",
    },
    "& .four": {
      fontSize: "30px",
      opacity: 0,
      animationDelay: "0.4s",
      animation: "$dot 1.3s infinite",
      animationFillMode: "forwards",
    },
    "& .five": {
      fontSize: "30px",
      opacity: 0,
      animationDelay: "0.5s",
      animation: "$dot 1.3s infinite",
      animationFillMode: "forwards",
    },
  },
  "@keyframes dot": {
    "0%": {
      opacity: "0",
    },
    "50%": {
      opacity: "0",
    },

    "100%": {
      opacity: "1",
    },
  },
}));
export default useStyles;
