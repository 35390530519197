import { CssBaseline, Paper } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { jssPreset, StylesProvider, ThemeProvider } from "@mui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  default as React,
  lazy,
  memo,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import Header from "../components/header/Header";
import actions from "../redux/actions";
import { darkTheme, makeTheme } from "../styles/globalTheme/makeTheme";
import { useTranslation } from "react-i18next";
import FormGenerator from "../components/formGenerator/FormGenerator";
import Footer from "../components/footer/Footer";
import { ContactUs } from "../pages/contactUs/ContactUs";

const Home = lazy(() => import("../pages/home/Home"));
const BasicPage = lazy(() => import("../pages/basicPage/BasicPage"));
const Gallery = lazy(() => import("../pages/gallery/Gallery"));
const Partners = lazy(() => import("../pages/partners/Partners"));
const Events = lazy(() => import("../pages/events/EventsPage"));
const Faq = lazy(() => import("../pages/faq/FaqPage"));
const VideoGalleryDetails = lazy(() =>
  import("../pages/video/VideoGalleryDetails")
);
const PhotoGalleryDetails = lazy(() =>
  import("../pages/photo/PhotoGalleryDetails")
);
const SliderDetailsPage = lazy(() =>
  import("../pages/sliderDetailsPage/SliderDetailsPage")
);
const Publications = lazy(() => import("../pages/publications/Publications"));
const CallForProposal = lazy(() =>
  import("../pages/callForProposal/CallForProposal")
);

const Survey = lazy(() => import("../pages/survey/Survey"));

const SurveyDetails = lazy(() => import("../pages/survey/SurveyDetails"));
const CategoriesPage = lazy(() =>
  import("../pages/categoriesPage/CategoriesPage")
);
const Error404 = lazy(() => import("../pages/error404/Error404"));

const TaskForcesCategoriesPage = lazy(() =>
  import("../pages/taskForcesCategoriesPage/TaskForcesCategoriespage")
);
const AboutT28 = lazy(() => import("../pages/aboutT28/AboutT28"));
const ExitForm = lazy(() => import("../components/shared/exitForm/ExitForm"));
const SiteMap = lazy(() => import("../pages/siteMap/SiteMap"));
const SearchResult = lazy(() => import("../pages/searchResults/SearchResult"));

function Routing() {
  const {
    basicTheme,
    basicTheme: { isRTL, isDark },
  } = useSelector((state) => state.theme_reducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef();
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const theme = isDark ? darkTheme(basicTheme) : makeTheme(basicTheme);

  const materialTheme = createTheme(theme);

  //GET_DATA_MENUS
  const {
    getDataMenus,
    getAll,
    homeLoaded,
    getById,
    getDataConf,
    getDataSlider,
    getDataPartners,
  } = actions;
  const reducer = useSelector((state) => state);

  const [menuItems, setMenuItems] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [headerMenuItems, setHeaderMenuItems] = useState([]);
  const [footerMenuItems, setFooterMenuItems] = useState([]);
  const [toggleSpinner, setToggleSpinner] = useState(false);
  const [greenlogo, setLogo] = useState(false);
  const [followColor, setFollowColor] = useState(false);
  let lang = JSON.parse(localStorage.getItem("t28Lang"))
    ? JSON.parse(localStorage.getItem("t28Lang"))
    : "en";
  useEffect(() => {
    if (!reducer?.homeLoading.homeLoaded) setToggleSpinner(true);
    else setToggleSpinner(false);
  }, [reducer?.homeLoading.homeLoaded]);

  useEffect(() => {
    if (!reducer.crud?.dataConfssReturned?.configurations?.length)
      dispatch(
        getDataConf({
          sort: "configuration",
        })
      );
  }, []);
  useEffect(() => {
    {
      if (!reducer?.crud?.allReturned?.survey?.length)
        dispatch(
          getAll({
            sort: "survey",
            data: {
              type: "survey",
            },
          })
        );
    }
    {
      if (!reducer.crud?.dataMenusReturned?.menuItems?.length)
        dispatch(getDataMenus({ sort: "menuItem/compact" }));
    }

    {
      dispatch(getAll({ sort: "category/parent/52" }));
    }

    {
      if (!reducer?.crud?.allReturned?.forms?.length)
        dispatch(
          getAll({
            sort: "form",
          })
        );
    }

    if (!reducer?.crud?.dataSliderReturned?.posts?.length)
      dispatch(
        getDataSlider({
          data: {
            type: "sliders",
          },
          sort: "post",
        })
      );
    if (!reducer?.crud?.dataPartnersReturned?.posts?.length)
      dispatch(
        getDataPartners({
          sort: "post",
          data: {
            type: "partners",
          },
        })
      );
    if (!reducer?.crud?.allReturned?.events?.length)
      dispatch(
        getAll({
          sort: "post",
          data: {
            type: "events",
          },
        })
      );
  }, []);
  useEffect(() => {
    if (
      reducer?.crud?.allReturned?.forms &&
      reducer?.crud?.dataSliderReturned?.posts &&
      reducer?.crud?.allReturned?.events &&
      reducer?.crud?.allReturned?.survey &&
      reducer.crud?.dataMenusReturned?.menuItems &&
      reducer?.crud?.dataPartnersReturned?.posts &&
      reducer.crud?.dataConfssReturned?.configurations
    ) {
      dispatch(
        homeLoaded({
          homeLoaded: true,
        })
      );
    }
  }, [
    reducer?.crud?.allReturned?.news,
    reducer?.crud?.dataSliderReturned?.posts,
    reducer?.crud?.allReturned?.events,
    reducer?.crud?.allReturned?.researches,
    reducer.crud?.dataMenusReturned.menuItems,
    reducer?.crud?.allReturned?.partners,
    reducer.crud?.dataConfssReturned?.configurations,
  ]);

  useEffect(() => {
    if (reducer?.crud?.dataConfssReturned?.configurations) {
      setConfigurations(reducer?.crud?.dataConfssReturned?.configurations);
    }
  }, [reducer.crud?.dataConfssReturned?.configurations]);

  useEffect(() => {
    if (reducer?.crud?.dataMenusReturned?.menuItems) {
      setMenuItems(reducer?.crud?.dataMenusReturned?.menuItems);
    }
  }, [reducer.crud?.dataMenusReturned.menuItems]);

  useEffect(() => {
    if (menuItems && menuItems?.length > 0) {
      setHeaderMenuItems(menuItems.filter((item) => item?.id === 1));
      setFooterMenuItems(menuItems.filter((item) => item?.id === 2));
    }
  }, [menuItems]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={materialTheme}>
        <CssBaseline />

        <Paper
          ref={componentRef}
          display="flex"
          flex-direction="column"
          width="100%"
          dir={isRTL ? "rtl" : "ltr"}
          id="full-page"
          style={{
            background: materialTheme.palette.background.default,
            position: "relative",
            minHeight: "100vh",
            height: "100%",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            filter:
              theme && theme?.globals?.type === "blind"
                ? "grayscale(100%)"
                : "inherit",
          }}
        >
          <Suspense fallback={<h1></h1>}>
            <Header
              menuItems={headerMenuItems.flatMap((item) => item.childrens)}
              greenlogo={greenlogo}
            />

            <Routes>
              <Route path={`/`} element={<Navigate replace to="/home" />} />
              <Route
                path={`/:language/home`}
                element={
                  <Home
                    configurations={configurations}
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/whatAboutT28`}
                element={
                  <SliderDetailsPage
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/:type/:alias`}
                element={
                  <BasicPage
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/page/:alias`}
                element={
                  <BasicPage
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/section/:type/:alias`}
                element={
                  <BasicPage
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/section/:alias`}
                element={
                  <CategoriesPage
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              {/* <Route
                path={`/:language/aboutT28`}
                element={
                  <AboutT28
                    alias="about-us"
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              /> */}
              <Route
                path={`/:language/privacy-policy`}
                element={
                  <AboutT28
                    alias="privacy-policy"
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/forms/:id`}
                element={
                  <FormGenerator
                    form={true}
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              {/* <Route
                path={`/:language/basic`}
                element={
                  <BasicPage
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              /> */}
              <Route
                path={`/:language/site-map`}
                element={
                  <SiteMap setLogo={setLogo} setFollowColor={setFollowColor} />
                }
              />
              <Route
                path={`/:language/gallery`}
                element={
                  <Gallery setLogo={setLogo} setFollowColor={setFollowColor} />
                }
              />
              <Route
                path={`/:language/events`}
                element={
                  <Events setLogo={setLogo} setFollowColor={setFollowColor} />
                }
              />
              <Route
                path={`/:language/partners`}
                element={
                  <Partners setLogo={setLogo} setFollowColor={setFollowColor} />
                }
              />
              <Route
                path={`/:language/contact-us`}
                element={
                  <ContactUs
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/faq`}
                element={
                  <Faq setLogo={setLogo} setFollowColor={setFollowColor} />
                }
              />
              <Route
                path={`/:language/videos/:alias`}
                element={
                  <VideoGalleryDetails
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/photos/:alias`}
                element={
                  <PhotoGalleryDetails
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />

              <Route
                path={`/:language/forms`}
                element={
                  <CallForProposal
                    type="callForProposal"
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />

              <Route
                path={`/:language/publications`}
                element={
                  <Publications
                    type="publications"
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/researches`}
                element={
                  <Publications
                    type="researches"
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/survey`}
                element={
                  <Survey setLogo={setLogo} setFollowColor={setFollowColor} />
                }
              />
              <Route
                path={`/:language/survey/:alias`}
                element={
                  <SurveyDetails
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/Communique`}
                element={
                  <Publications
                    type="communique"
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/policy`}
                element={
                  <Publications
                    type="policyBriefs"
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/task-forces/:alias`}
                element={
                  <TaskForcesCategoriesPage
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/not-found`}
                element={
                  <Error404
                    configurations={configurations}
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/404`}
                element={
                  <Error404
                    configurations={configurations}
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={"*"}
                element={
                  <Error404
                    configurations={configurations}
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
              <Route
                path={`/:language/search-results`}
                element={
                  <SearchResult
                    setLogo={setLogo}
                    setFollowColor={setFollowColor}
                  />
                }
              />
            </Routes>
            {/* {!window?.location?.href?.includes("home") &&
              !window?.location?.href?.includes("not-found") && (
                <FormGenerator />
              )} */}

            <Footer
              menuItems={footerMenuItems.flatMap((item) => item.childrens)}
              configurations={configurations}
            />
            {/* {toggleSpinner ? (
              <div
                style={{
                  display: "flex",
                  top: 0,
                  bottom: 0,
                  zIndex: 6500,
                  backdropFilter: "blur(2px)",
                  right: 0,
                  backgroundColor: "#ffffff1a",
                  left: 0,
                  position: "fixed",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BeatLoader
                  color={theme.palette.primary.main}
                  loading={true}
                  size={15}
                  speedMultiplier={1}
                />
              </div>
            ) : null} */}
          </Suspense>
          <ExitForm />
        </Paper>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default memo(Routing);
