import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/ar-sa";
import "moment/locale/en-au";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiTwotoneMail } from "react-icons/ai";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useSelector } from "react-redux";
import useStyles from "../../../styles/components/footer/footerStyle";
export const AboutSite = ({ configurations }) => {
  const classes = useStyles();
  const {
    basicTheme: { isRTL },
  } = useSelector((state) => state.theme_reducer);
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [updated, setUpdated] = useState("");
  const [visitorsNum, setvisitorsNum] = useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (configurations && configurations?.length > 0) {
      setEmail(configurations.find((item) => item?.key === "EMAIL"));
      setvisitorsNum(
        configurations.find((item) => item?.key === "VISITOR_COUNT")
      );
      setUpdated(
        configurations.find((item) => item?.key === "HOME_PAGE_LAST_UPDATE")
      );
    }
  }, [configurations]);

  const handleChange = (isVisible) => {
    if (!visible) setVisible(isVisible);
    //to prevent it from running the animation after the first appearance
  };
  return (
    <Box className={classes.rootInfo}>
      {/* {location?.value && (
        <Box className={classes.aboutBox}>
          <MdPlace className={classes.iconInfoPosition} />
          <Typography className={classes.titleInfo}>
            {location?.value}
          </Typography>
        </Box>
      )}

      {phone?.value && (
        <Box className={classes.aboutBox}>
          <MdOutlinePhoneInTalk className={classes.iconInfo} />
          <bdi>
            <Typography className={clsx(classes.titleInfo, classes.marginTop8)}>
              {phone?.value}
            </Typography>
          </bdi>
        </Box>
      )} */}

      {email?.value && (
        <Box className={classes.aboutBox}>
          <AiTwotoneMail className={classes.iconInfo} />
          <Typography className={classes.titleInfo}>{email?.value}</Typography>
        </Box>
      )}

      {updated?.value && (
        <Box className={classes.aboutBox}>
          <Typography className={classes.titleInfo}>
            {t("FOOTER.update")}
          </Typography>
          <Typography className={classes.titleInfoNum}>
            {moment(updated?.value)
              .format("DD MMM YYYY")
              .replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d))}
          </Typography>
        </Box>
      )}

      {visitorsNum?.value && (
        <Box className={classes.aboutBox}>
          <Typography className={classes.titleInfo}>
            {t("FOOTER.num")}
          </Typography>

          <Typography className={classes.titleInfoNum}>
            <VisibilitySensor
              onChange={handleChange}
              partialVisibility={"bottom"}
              offset={{
                bottom: 20,
              }}
              delayedCall
            >
              <CountUp
                start={0}
                end={visible ? visitorsNum?.value : 0}
                duration={visitorsNum?.value > 500 ? 3 : 6}
              />
            </VisibilitySensor>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
