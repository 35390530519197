import { Rotate90DegreesCcw } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    width: "100%",
    height: "100%",
    boxShadow: "unset",
    backgroundColor: "unset",
    "& > div": {
      "&::-webkit-scrollbar": {
        width: "0.4em",
        height: 8,
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
      },
    },
    "& button.downloadBtn": {
      fontFamily:
        theme.direction === "rtl"
          ? `${theme.globals.fontFamily.ar}!important`
          : `${theme.globals.fontFamily.en}!important`,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: theme.globals.fontSize.s - 2,
      lineHeight: "19px",
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      boxSizing: "border-box",
      borderRadius: "3px",
      marginBottom: "15px",
    },

    "& table": {
      background: theme.palette.type === "dark" && theme.globals.colors.card,

      "& caption": {
        padding: 0,
      },
      "& svg.icon": {
        color: theme.palette.primary.main,
        fontSize: theme.globals.fontSize.xl - 1,
      },

      "& svg.comment": {
        color: theme.palette.primary.main,
        fontSize: theme.globals.fontSize.lg - 2,
      },

      "& thead": {
        background:
          theme.palette.type === "dark"
            ? theme.globals.colors.card
            : theme.globals.colors.lightgray,
        "& th.MuiTableCell-root": {
          borderBottom: "0",
          padding: 6,

          "& button": {
            marginLeft: "auto",
            marginRight: "auto",

            "& span.MuiButton-label div": {
              fontFamily:
                theme.direction === "rtl"
                  ? `${theme.globals.fontFamily.ar}!important`
                  : `${theme.globals.fontFamily.en}!important`,
              color: theme.palette.primary.main,
              fontSize: theme.globals.fontSize.xs,
            },
          },
        },

        "& th:first-of-type": {
          borderRadius: "4px 0px 0px 4px",
        },

        "& th:last-of-type": {
          borderRadius: "0 4px 4px 0",
        },

        "& tr th": {
          background: "unset",
          textAlign: "start",
          fontFamily:
            theme.direction === "rtl"
              ? `${theme.globals.fontFamily.ar}!important`
              : `${theme.globals.fontFamily.en}!important`,
          fontWeight: "600",
          color: theme.palette.primary.main,
          "& span": {
            "& button": {
              fontFamily:
                theme.direction === "rtl"
                  ? `${theme.globals.fontFamily.ar}!important`
                  : `${theme.globals.fontFamily.en}!important`,
              "&.MUIDataTableBodyCell-root": {},
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: theme.globals.fontSize.xs - 2,
              color: theme.globals.colors.gray,
              marginRight: "unset",
              marginLeft: "unset",
              "& div": {
                fontFamily:
                  theme.direction === "rtl"
                    ? `${theme.globals.fontFamily.ar}!important`
                    : `${theme.globals.fontFamily.en}!important`,
              },
              "& .MUIDataTableHeadCell-data": {
                fontFamily:
                  theme.direction === "rtl"
                    ? `${theme.globals.fontFamily.ar}!important`
                    : `${theme.globals.fontFamily.en}!important`,
              },
            },
          },
        },
      },

      "& tbody": {
        "& tr": {
          "& td": {
            fontFamily:
              theme.direction === "rtl"
                ? `${theme.globals.fontFamily.ar}!important`
                : `${theme.globals.fontFamily.en}!important`,
            fontStyle: "normal",

            fontSize: theme.globals.fontSize.s - 2,
            lineHeight: "16px",
            color:
              theme.palette.type === "dark"
                ? theme.globals.colors.white
                : theme.palette.primary.main,
            textAlign: "start",

            "& > div": {
              display: "grid",
              placeItems: "center",
              fontFamily:
                theme.direction === "rtl"
                  ? `${theme.globals.fontFamily.ar}!important`
                  : `${theme.globals.fontFamily.en}!important`,
              color: theme.globals.colors.black,
              fontSize: theme.globals.fontSize.xs,
            },
          },

          "& td:nth-child(6), & td:nth-child(5)": {
            cursor: "pointer",
            "& div >button": {
              "&:hover": {
                backgroundColor: "unset",
              },
            },
            "& div svg": {
              transform: "scale(1)",
              transition: "transform 0.4s",
              color: theme.palette.secondary.main,
              fontSize: theme.globals.fontSize.title - 6,
              backgroundColor: "unset",
              [theme.breakpoints.down(600)]: {
                fontSize: theme.globals.fontSize.title - 17,
              },
            },

            "&:hover div svg": {
              transform: "scale(1.3)",
            },
          },
        },
      },
      "& tfoot": {
        "& tr": {
          "& td": {
            "& > div": {
              "& > div": {
                direction:
                  theme.direction === "rtl" ? "ltr!important" : "ltr!important",
                color:
                  theme.palette.type === "dark"
                    ? theme.globals.colors.white
                    : theme.palette.primary.main,
                "& svg": {
                  color:
                    theme.palette.type === "dark"
                      ? theme.globals.colors.white
                      : theme.palette.primary.main,
                },
                overflow: "hidden",
                "& > div": {
                  "& *": {
                    marginBottom: "0px!important",
                  },
                  "& > p": {
                    fontFamily: `${theme.globals.fontFamily.en}!important`,
                    color:
                      theme.palette.type === "dark"
                        ? theme.globals.colors.white
                        : theme.palette.primary.main,
                    [theme.breakpoints.down(500)]: {
                      fontSize: "12px!important",
                    },
                  },
                  "& > div": {
                    marginLeft: 0,
                    [theme.breakpoints.down(500)]: {
                      fontSize: "12px!important",
                    },
                    "& Button": {
                      padding: 0,

                      "& svg": {
                        color:
                          theme.palette.type === "dark"
                            ? theme.globals.colors.white
                            : theme.palette.primary.main,
                        transform:
                          theme.direction === "rtl"
                            ? "rotate(-180deg)"
                            : "inherit",
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },

  news: {
    boxShadow: "0px 2px 44px 0px rgba(52, 75, 97, 0.10)!important",

    "& tbody": {
      "& *": {
        marginBottom: "0px!important",
      },
      "& tr": {
        // background:
        //   theme.palette.type === "dark" && theme.globals.colors.primary,
        "& td:nth-child(2)": {
          "& > div": {
            height: 25,
            width: 25,
            marginLeft: "auto",
            marginRight: "auto",
          },
        },
        "& td:nth-child(1)": {
          maxWidth: 90,

          "& > div": {
            direction: theme.direction,
            marginLeft: "auto",
            marginRight: "auto",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textAlign: "center",

            [theme.breakpoints.down(550)]: {
              fontSize: `${theme.globals.fontSize.xs}px!important`,
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            },
          },
        },

        "& td:nth-child(4)": {
          cursor: "pointer",

          "& div svg": {
            transform: "scale(1)",
            transition: "transform 0.4s",
          },

          "&:hover div svg": {
            transform: "scale(1.3)",
          },
        },
      },
    },
    "& .MUIDataTablePagination": {},
  },
}));
