export const makeTheme = ({ currentTheme, isRTL, isDark }) => {
  return {
    direction: isRTL ? "rtl" : "ltr",
    palette: {
      background: {
        default: "#fff",
      },
      primary: {
        main: currentTheme.elements.colors.black,
      },
      secondary: {
        main: currentTheme.elements.colors.gray,
      },
      textMed: {
        main: currentTheme.elements.colors.textMed,
      },
      date: {
        main: currentTheme.elements.colors.steel,
      },
    },
    name: currentTheme.name,
    globals: { ...currentTheme.elements, direction: isRTL ? "rtl" : "ltr" },
  };
};
export const darkTheme = ({ currentTheme, isRTL, isDark }) => {
  return {
    direction: isRTL ? "rtl" : "ltr",

    palette: {
      type: "dark",
      background: {
        default: "#0A1929",
      },
      primary: {
        main: currentTheme.elements.colors.primary,
      },
      secondary: {
        main: currentTheme.elements.colors.gray,
      },
      black: {
        main: "#fff",
      },
      textMed: {
        main: currentTheme.elements.colors.textMed,
      },
      gray: {
        main: "#fff",
      },
      date: {
        main: currentTheme.elements.colors.steel,
      },
    },
    globals: {
      ...currentTheme.elements,
      colors: {
        ...currentTheme.elements.colors,
        field: "#fff",
        primary: "#0A1929",
        card: "#132F4C",
        platinum: "#132F4C",
        gray: "#fff",
        black: "#fff",
      },
    },
  };
};
export const blindTheme = ({ currentTheme, isRTL }) => {
  return {
    direction: isRTL ? "rtl" : "ltr",
    palette: {
      type: "blind",
      background: {
        default: "#fff",
      },
      primary: {
        main: currentTheme.elements.colors.primary,
      },
      textMed: {
        main: currentTheme.elements.colors.textMed,
      },
      secondary: {
        main: currentTheme.elements.colors.gray,
      },
      black: {
        main: currentTheme.elements.colors.black,
      },
      gray: {
        main: currentTheme.elements.colors.gray,
      },
    },
    globals: { ...currentTheme.elements, direction: isRTL ? "rtl" : "ltr" },
  };
};
