import theme from "./groups/theme";
import crud from "./groups/crudActions";
import filterPosts from "./groups/filterPosts";
import files from "./groups/file";
import contactUs from "./groups/contactUs";
import homeLoading from "./groups/homeLoading";
import forms from "./groups/forms";
import survey from "./groups/survey";
import search from "./groups/search";

export default {
  ...theme,
  ...crud,
  ...files,
  ...filterPosts,
  ...contactUs,
  ...survey,
  ...homeLoading,
  ...search,
  ...forms,
};
