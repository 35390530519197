import { Button, Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../../styles/components/button/button";
import clsx from "clsx";
const CustomButton = (props) => {
  const {
    className,
    label,
    path,
    item,
    primary,
    secondry,
    newsletter,
    primaryClasses,
  } = props;

  const classes = useStyles();

  let lang = JSON.parse(localStorage.getItem("t28Lang"))
    ? JSON.parse(localStorage.getItem("t28Lang"))
    : "en";

  const homePage = window.location.pathname === `/${lang}/home`;

  return (
    <Box
      className={
        !homePage && (primary || secondry)
          ? classes.buttonBox
          : classes.buttonCenter
      }
    >
      {path ? (
        <Link to={`/${lang + path}`}>
          <Button
            className={
              primary
                ? clsx(classes.primary, primaryClasses)
                : secondry
                ? classes.secondry
                : newsletter
                ? classes.newsletter
                : className
            }
            onClick={props.onClick}
          >
            <span>{label}</span>
            {item}
          </Button>
        </Link>
      ) : (
        <Button
          className={
            primary
              ? clsx(classes.primary, primaryClasses)
              : secondry
              ? classes.secondry
              : newsletter
              ? classes.newsletter
              : className
          }
          onClick={props.onClick}
        >
          <span>{label}</span>
          {item}
        </Button>
      )}
    </Box>
  );
};

export default CustomButton;
