import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { Box, Fab } from "@mui/material";
import React from "react";
import useStyles from "../../styles/components/scrollButton/scrollButton";
const ButtonScroll = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Fab
        color="inherit"
        onClick={(e) =>
          window.scrollTo({
            top: 0,
            right: 0,
            behavior: "smooth",
          })
        }
        aria-label="close"
        className={classes.BoxButton}
      >
        <MdOutlineKeyboardArrowUp className={classes.icon} />
      </Fab>
    </Box>
  );
};

export default ButtonScroll;
