import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
  },
  HelperTextIcon: {
    color:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.field}!important`
        : `${theme.globals.colors.darkBlue}!important`,
    marginTop: 10,
  },
  helperText: {
    width: "115%",
    marginTop: 8,
    color:
      theme.palette.type === "dark"
        ? `${theme.globals.colors.field}!important`
        : `${theme.globals.colors.darkBlue}!important`,
    fontSize: `${theme.globals.fontSize.sm}px!important`,
    fontFamily: `${theme.globals.fontFamily.en}!important`,
    marginLeft: 6,
    fontWeight: 400,
    marginRight: 0,
  },
  description: {
    color:
      theme.palette.type === "dark" &&
      `${theme.globals.colors.field}!important`,
    "& *": {
      background: "none!important",

      color:
        theme.palette.type === "dark" &&
        `${theme.globals.colors.field}!important`,
      fontFamily: `${theme.globals.fontFamily.en}!important`,
    },
    "& p": {
      background: "none!important",

      color:
        theme.palette.type === "dark" &&
        `${theme.globals.colors.field}!important`,
      fontSize: theme.globals.fontSize.sm,
      fontFamily: `${theme.globals.fontFamily.en}!important`,
      "& *": {
        background: "none!important",
        color:
          theme.palette.type === "dark" &&
          `${theme.globals.colors.field}!important`,

        fontFamily: `${theme.globals.fontFamily.en}!important`,
      },
      [theme.breakpoints.down(1200)]: {
        fontSize: `${theme.globals.fontSize.sm - 1}px!important`,
      },
      [theme.breakpoints.down(450)]: {
        fontSize: `${theme.globals.fontSize.sm - 2}px!important`,
      },
    },

    textAlign: "justify",
    //textTransform: "capitalize",
    marginTop: "20px!important",
    [theme.breakpoints.down(900)]: {
      fontSize: `${theme.globals.fontSize.sm}px!important`,
      marginTop: "10px!important",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    "& >div >div": {
      "& >form >div": {
        "& *": {
          fontSize: `${theme.globals.fontSize.sm + 2}px`,
          // background: "none!important",

          [theme.breakpoints.down(500)]: {
            fontSize: `${theme.globals.fontSize.sm}px`,
          },
        },
        "& .static": {
          color: `${theme.globals.colors.lightGreen}!important`,
          fontWeight: `${theme.globals.fontWeight.lg}!important`,
        },
        "& .form-group": {
          marginBottom: "10px!important",
          "& > div > div > div > div > iframe": {
            [theme.breakpoints.down(500)]: {
              WebkitTransform: "scale(0.80) !important",
              WebkitTransformOrigin: "0 0 !important",
            },
          },
          "& .custom-control": {
            "& >label": {
              background: "none!important",
              direction: "ltr!important",
              color:
                theme.palette.type === "dark"
                  ? `${theme.globals.colors.field}!important`
                  : `${theme.palette.primary.main}!important`,
              "& *": {
                background: "none!important",
                padding: "0 0 10px",
              },
            },
          },
          "& .MuiDropzoneArea-root": {
            width: "100%",
            minHeight: "45px",
            height: "auto",
            border: "0.5px solid #989898",
            borderRadius: "8px",
            zIndex: 1,
            "& .MuiDropzoneArea-textContainer": {
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              paddingInline: "10px",
              marginTop: "14px",
            },
            "& .MuiDropzoneArea-text": {
              marginTop: "0px",
              marginBottom: "0px",
              fontSize:
                theme.direction == "rtl" ? "16px!important" : "14px!important",
              fontWeight: "400",
              color: "#9C9C9C",
            },
            "& .MuiDropzoneArea-icon": {
              right: "20px",
              height: "26px",
              fontSize: "30px",
              color: "#9C9C9C",
              position: "absolute",
            },
            "& .MuiGrid-spacing-xs-8 > .MuiGrid-item": {
              padding: "10px",
              "& *": {
                fontFamily:
                  theme.direction === "rtl"
                    ? `${theme.globals.fontFamily.ar}!important`
                    : `${theme.globals.fontFamily.en}!important`,
              },
              "& .MuiDropzonePreviewList-removeButton": {
                backgroundColor: "white",
                color: "rgba(144,8,8,1)",
                position: "absolute",
              },
              "&:hover": {
                "& .MuiDropzonePreviewList-removeButton": {
                  right: "70px!important",

                  [theme.breakpoints.down(900)]: {
                    right: "20px!important",
                    width: "45px",
                    height: "45px",
                  },
                  [theme.breakpoints.down(520)]: {
                    right: "-10px!important",
                  },
                },
                "& .MuiSvgIcon-root": {
                  marginRight: "0px!important",
                  marginLeft: "0px!important",
                },
              },
              "& .MuiDropzonePreviewList-image": {
                height: "70px",
                width: "110px",
              },
            },
            "& .MuiGrid-spacing-xs-8": {
              width: "100%",
              margin: "0px",
            },
            "&:focus": {
              outline: "none!important",
              border: "0.5px solid #1f627f!important",
            },
          },
          "& >label": {
            padding: "0 0 10px",
            background: "none!important",
            direction: "ltr!important",
            color:
              theme.palette.type === "dark"
                ? `${theme.globals.colors.field}!important`
                : `${theme.palette.primary.main}!important`,
            "& *": {
              background: "none!important",
              padding: "0 0 10px",
            },
          },
          "& .image-upload-container": {
            "& >div": {
              "& .image-upload-control": {
                color:
                  theme.palette.type === "dark"
                    ? `${theme.globals.colors.field}!important`
                    : `${theme.palette.primary.main}!important`,
                "& *": {
                  paddingInlineStart: "0px!important",
                  color:
                    theme.palette.type === "dark"
                      ? `${theme.globals.colors.field}!important`
                      : `${theme.palette.primary.main}!important`,
                },
              },
            },
          },
          "& .react-star-rating": {
            direction: "ltr!important",

            "& *": {
              fontSize: `${theme.globals.fontSize.sm + 10}px!important`,
            },
          },
          "& >label .label-required": {
            background: "none!important",
            color: "red!important",
            fontSize: `${theme.globals.fontSize.sm - 1}px!important`,
            fontWeight: "500!important",
          },
        },
      },
      "& .btn-toolbar": {
        justifyContent: "center",
        display: "flex",

        "& >input,a": {
          border: "1px solid!important",
          borderColor:
            theme.palette.type === "dark"
              ? `${theme.globals.colors.field}!important`
              : `${theme.palette.primary.main}!important`,
          color:
            theme.palette.type === "dark"
              ? `${theme.globals.colors.field}!important`
              : `${theme.palette.primary.main}!important`,
          fontSize:
            theme.direction === "rtl"
              ? `${theme.globals.fontSize.sm - 2}px!important`
              : `${theme.globals.fontSize.sm}px!important`,
          fontFamily:
            theme.direction === "rtl"
              ? `${theme.globals.fontFamily.ar}!important`
              : `${theme.globals.fontFamily.en}!important`,
          borderRadius: "3px !important",
          height: 35,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "10px",
          "&:hover": {
            color:
              theme.palette.type === "dark"
                ? `${theme.globals.colors.field}!important`
                : `${theme.globals.colors.white}!important`,
            background: `${theme.globals.colors.primary}!important`,

            "& span": {
              color: theme.globals.colors.red,
            },
          },
          width: 110,
          "& span": {
            fontFamily:
              theme.direction === "rtl"
                ? `${theme.globals.fontFamily.ar}!important`
                : `${theme.globals.fontFamily.en}!important`,
            fontSize:
              theme.direction === "rtl"
                ? theme.globals.fontSize.sm - 2
                : theme.globals.fontSize.sm,
          },
        },
      },
    },
  },
}));
export default useStyles;
